import React from 'react';
import iconRefresh from 'static/images/icon-refresh.svg';
import { StyledRefreshButton } from './PhoneNumberPaginationStyles';
import styled from 'styled-components';
import intl from 'react-intl-universal';

const StyledParagraph = styled.p`
	text-align: center;
`;

const StyledRefreshButtonImage = styled.img`
	margin-right: 3px;
`;
const PhoneNumberPagination = props => {
	return (
		<StyledParagraph>
			<StyledRefreshButton type='button' onClick={props.handlePagination}>
				<StyledRefreshButtonImage
					src={iconRefresh}
					alt={intl.get('ChooseNumber.PhoneNumberContainer.RefreshList')}
				/>
				{intl.get('ChooseNumber.PhoneNumberContainer.RefreshList')}
			</StyledRefreshButton>
		</StyledParagraph>
	);
};

export default PhoneNumberPagination;
