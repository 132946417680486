import intl from 'react-intl-universal';
import { convertTextToTitleCase, decryptString, currencyFormatter } from 'util/Common';

const getOfferCodeFromSession = () => {
	return window.sessionStorage.getItem('offercode') !== undefined ||
		window.sessionStorage.getItem('offercode') !== ''
		? JSON.parse(window.sessionStorage.getItem('offercode'))
		: undefined;
};

const buildOfferCodeCollection = (offer_code) => {
	let collection = [];
	const offerCodeCollection = getOfferCodeObjectByOfferCode(offer_code, true);
	if (offerCodeCollection && offerCodeCollection.duration) {
		const ockeysDuration = offerCodeCollection.duration;
		Object.keys(ockeysDuration).forEach((duration) => {
			//duration == monthly or annually
			//check the offer to see if its enabled and add to bucket
			Object.keys(ockeysDuration[duration]).forEach((offerCodeItem) => {
				if (
					ockeysDuration[duration][offerCodeItem].hasOwnProperty('enabled') &&
					ockeysDuration[duration][offerCodeItem].enabled &&
					ockeysDuration[duration][offerCodeItem].hasOwnProperty('pricing')
				) {
					const translation = offerDetailsTranslation(ockeysDuration[duration][offerCodeItem]);
					const option = {
						details: ockeysDuration[duration][offerCodeItem],
						customPlanDropDown: translation && translation.customPlanDropDown,
						name: translation && translation.planNameForCustomPlanDropDown,
						value: ockeysDuration[duration][offerCodeItem].offer_code,
						duration: duration,
						savings: ockeysDuration[duration][offerCodeItem].savings,
						strike: ockeysDuration[duration][offerCodeItem].hasOwnProperty('strike_price_formatted')
							? ockeysDuration[duration][offerCodeItem]['strike_price_formatted']
							: '',
					};
					collection.push(option);
				}
			});
		});
		return collection;
	} else {
		//returns single offercode obj
		return offerCodeCollection;
	}
};

/* Get Offercode Object by offercode */
const getOfferCodeObjectByOfferCode = (offerCodeString, needParent = false) => {
	//TODO: refactor this mess
	//with.every return false basically means it found the result; return true means keep searching
	let offerCodeDetails;
	let resultsFound = false;
	let continueSearching = true;

	const planDrations = ['monthly', 'annually'];
	const OfferCodeList = decryptString(sessionStorage.getItem('_collection'));
	const planObject = OfferCodeList.find((e) => e.group_name === offerCodeString);

	if (planObject && needParent) {
		const searchResults = OfferCodeList.find((e) => e.group_name === offerCodeString);
		if (searchResults) {
			resultsFound = true;
			return searchResults;
		} else {
			planDrations.every((duration) => {
				Object.keys(planObject.duration[duration]).every(() => {
					const ocDetails = planObject.duration[duration].find(
						(e) => e.offer_code === offerCodeString
					);
					if (ocDetails && ocDetails.enabled) {
						offerCodeDetails = ocDetails;
						resultsFound = true;
						continueSearching = false;
						return continueSearching;
					}
					continueSearching = true;
					return continueSearching;
				});
				return continueSearching;
			});
		}
	}
	if (planObject && !resultsFound) {
		//found in the parent group name list
		planDrations.every((duration) => {
			Object.keys(planObject.duration[duration]).every(() => {
				const ocDetails = planObject.duration[duration].find(
					(e) => e.offer_code === offerCodeString
				);
				try {
					if (ocDetails && ocDetails.enabled) {
						offerCodeDetails = ocDetails;
						resultsFound = true;
						continueSearching = false;
						return continueSearching;
					}
				} catch {}
				continueSearching = true;
				return continueSearching;
			});
			return continueSearching;
		});
	} else {
		planDrations.every((duration) => {
			let matchFound = true;
			try {
				Object.keys(OfferCodeList).every((item) => {
					const ocDetails =
						OfferCodeList[item].duration[duration] &&
						OfferCodeList[item].duration[duration].find((e) => e.offer_code === offerCodeString);
					if (needParent && ocDetails) {
						offerCodeDetails = OfferCodeList[item];
						matchFound = false;
						return matchFound;
					} else {
						if (ocDetails && ocDetails.enabled) {
							offerCodeDetails = ocDetails;
							matchFound = false;
							return matchFound;
						}
					}
					return matchFound;
				});
				return matchFound;
			} catch (e) {
				//console.log(e);
			}
		});
	}

	if (offerCodeDetails === undefined) {
		//use default since we didnt find any matches
		offerCodeDetails = OfferCodeList.find(
			(e) => e.group_name === intl.get('SystemConfig.DefaultOfferCode')
		);
		planDrations.every((duration) => {
			Object.keys(offerCodeDetails.duration[duration]).every(() => {
				const ocDetails = offerCodeDetails.duration[duration].find(
					(e) => e.offer_code === intl.get('SystemConfig.DefaultOfferCode')
				);
				try {
					if (ocDetails && ocDetails.enabled) {
						offerCodeDetails = ocDetails;
						resultsFound = true;
						continueSearching = false;
						return continueSearching;
					}
				} catch {}
				continueSearching = true;
				return continueSearching;
			});
			return continueSearching;
		});
	}
	return offerCodeDetails;
};

const offerDetailsTranslation = (offerCodeDetails) => {
	if (!offerCodeDetails) return;

	const dayjs = require('dayjs');
	const relativeTime = require('dayjs/plugin/relativeTime');
	dayjs.extend(relativeTime);

	switch (offerCodeDetails.billing_period) {
		case 1: {
			const results = {
				subscriptionFee: currencyFormatter(
					offerCodeDetails.pricing.subscription_fee +
						offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0)
				),
				period: intl.get('OfferCodeDetails.BillingPeriod.Monthly.Period'),
				periodA: intl.get('OfferCodeDetails.BillingPeriod.Monthly.PeriodA'),
				name: intl.get('OfferCodeDetails.BillingPeriod.Monthly.Name'),
				planNameForCustomPlanDropDown: `${planNameTranslationLookup(
					offerCodeDetails.plan,
					intl.get('SystemConfig.Brand')
				)}
					${currencyFormatter(
						offerCodeDetails.pricing.subscription_fee +
							offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0)
					)}
					${intl.get('PlanSummary.Duration.PlanTimeFrame').toLowerCase()}`,
				customPlanDropDown: {
					planName: offerCodeDetails.plan,
					subscription_fee: currencyFormatter(
						offerCodeDetails.pricing.subscription_fee +
							offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0)
					),
					currency: offerCodeDetails && offerCodeDetails.currency,
					taxSymbol:
						offerCodeDetails && offerCodeDetails.tax_symbol ? offerCodeDetails.tax_symbol : '',
					planDuration: intl.get('PlanSummary.Duration.PlanTimeFrame').toLowerCase(),
				},
				trialEndDate: dayjs(offerCodeDetails.pricing.free_trial_end_date).isValid()
					? dayjs(offerCodeDetails.pricing.free_trial_end_date).format('MMMM DD, YYYY')
					: '',
				lowPaymentFirstMonth:
					offerCodeDetails && offerCodeDetails.offer_code.toLowerCase().includes('500-1mo'), //this is a hack for their $5 OC EFAX_PLUS_USD_1695_500-1MO_NOACT_NOTRI_150PGIFT_1500SEND
				trialDays:
					offerCodeDetails.pricing.free_trial_duration > 0 &&
					offerCodeDetails.pricing.number_of_free_days > 0
						? intl.get('OfferCodeDetails.BillingPeriod.Monthly.TrialDays.A', {
								days: offerCodeDetails.pricing.number_of_free_days,
						  })
						: dayjs(offerCodeDetails.pricing.free_trial_end_date).toNow(true) ===
						  intl.get('OfferCodeDetails.BillingPeriod.Monthly.TrialDays.B')
						? intl.get('OfferCodeDetails.BillingPeriod.Monthly.TrialDays.C')
						: intl.get('OfferCodeDetails.BillingPeriod.Monthly.TrialDays.D', {
								hasDate: dayjs(offerCodeDetails.pricing.free_trial_end_date).toNow(true),
						  }),
				overrideOneTimeFee: false,
				dueTodayFee: currencyFormatter(
					offerCodeDetails.pricing.number_of_free_days > 0 ||
						offerCodeDetails.pricing.free_trial_duration > 0
						? '0.00'
						: offerCodeDetails.pricing.subscription_fee +
								offerCodeDetails.pricing.activation_fee +
								(offerCodeDetails.pricing.subscription_fee +
									offerCodeDetails.pricing.activation_fee) *
									(offerCodeDetails.tax_rate ?? 0)
				),
			};
			return results;
		}
		case 12: {
			let subPrice;
			if (intl.get('SystemConfig.DisableAnnualCostBreakdown')) {
				subPrice =
					offerCodeDetails.pricing.subscription_fee +
					offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0);
			} else {
				subPrice =
					Math.round(
						(parseFloat(
							offerCodeDetails.pricing.subscription_fee +
								offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0)
						) /
							12) *
							100
					) / 100;
			}

			const grandTotal =
				offerCodeDetails.pricing.subscription_fee +
				offerCodeDetails.pricing.activation_fee +
				(offerCodeDetails.pricing.subscription_fee + offerCodeDetails.pricing.activation_fee) *
					(offerCodeDetails.tax_rate ?? 0);

			const results = {
				period: intl.get('OfferCodeDetails.BillingPeriod.Annually.Period'),
				periodA: intl.get('OfferCodeDetails.BillingPeriod.Annually.PeriodA'),
				name: intl.get('OfferCodeDetails.BillingPeriod.Annually.Name'),
				overrideOneTimeFee: false,
				subscriptionFee: currencyFormatter(
					offerCodeDetails.pricing.subscription_fee +
						offerCodeDetails.pricing.subscription_fee * (offerCodeDetails.tax_rate ?? 0)
				),
				customPlanDropDown: {
					planName: offerCodeDetails.plan,
					vendorName: '',
					subscription_fee: currencyFormatter(
						parseFloat(subPrice.toFixed(subPrice % 1 != 0 ? 2 : 0))
					),
					currency: offerCodeDetails && offerCodeDetails.currency,
					taxSymbol:
						offerCodeDetails && offerCodeDetails.tax_symbol ? offerCodeDetails.tax_symbol : '',
					planDuration: intl.get('PlanSummary.Duration.PlanTimeFrame').toLowerCase(),
				},
				dueTodayFee: currencyFormatter(
					offerCodeDetails.pricing.number_of_free_days > 0 ||
						offerCodeDetails.pricing.free_trial_duration > 0
						? '0.00'
						: grandTotal.toFixed(subPrice % 1 != 0 ? 2 : 0)
				),
			};
			return results;
		}
	}
};

const planNameTranslationLookup = (planName, brand) => {
	switch (brand.toLowerCase()) {
		case 'myfax': {
			switch (planName.toLowerCase()) {
				case 'best_value': {
					return 'Home Office User';
				}
				case 'most_popular': {
					return 'Small Business User';
				}
				case 'fax_more': {
					return 'Power User';
				}
				default:
					return convertTextToTitleCase(planName);
			}
		}
		case 'metrofax': {
			switch (planName.toLowerCase()) {
				case 'value':
				case 'mtrofax_lite': {
					return 'Best Value';
				}
				default:
					return convertTextToTitleCase(planName);
			}
		}
		default: {
			return convertTextToTitleCase(planName);
		}
	}
};

export {
	offerDetailsTranslation,
	getOfferCodeObjectByOfferCode,
	buildOfferCodeCollection,
	getOfferCodeFromSession,
	planNameTranslationLookup,
};
