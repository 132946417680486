import { HttpClient } from 'lib/funnel-auditor';
import { v4 } from 'uuid';
import config from 'systemconfig/index';
import {
	getUserId,
	getClientSessionId,
	determineApiUrlEndpoint,
	removeDuplicatesFromResponse,
	getHostNameData
} from 'util/Common';
import { userIpAddress } from 'util/Cookie';
import intl from 'react-intl-universal';

let hostName;

const getOfferCodeDetails = async (offerCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';

	try {
		const conf = {
			url:
				determineApiUrlEndpoint() +
				`/getOfferCodeDetails?offerCode=${offerCode}&ip=${userIpAddress()}&id=${getUserId()}&site=${
					hostName.fullDomainName
				}&sub=${getSubFolder}&ver=${config.version}&session_id=${getClientSessionId()}&isCustom=${
					payload && payload.isCustom ? payload.isCustom : false
				}`,
			method: 'GET',
			cache: true,
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: '',
				controlName: '',
				time: new Date().getTime(),
				event: 'offerCode was returned',
				value: offerCode,
				correlation_id,
				//	page: payload.pathname,
				search: payload.search
			}
		};

		if (payload.isCustom) {
			await HttpClient.request(conf).then(response => {
				if (response && response.data) {
					window.sessionStorage.setItem('_collection', response.data);
				}
			});
		} else {
			let response = await HttpClient.request(conf);
			return Promise.resolve(response);
		}
	} catch (error) {
		const conf = {
			url:
				determineApiUrlEndpoint() +
				`/getOfferCodeDetails?offerCode=${intl.get(
					'SystemConfig.DefaultOfferCode'
				)}&ip=${userIpAddress()}&id=${getUserId()}&site=${
					hostName.fullDomainName
				}&sub=${getSubFolder}&ver=${config.version}&session_id=${getClientSessionId()}&isCustom=${
					payload.isCustom
				}`,
			method: 'GET',
			cache: true,
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: '',
				controlName: '',
				time: new Date().getTime(),
				event: 'invalid OC was passed, switched to default oc',
				value: offerCode,
				correlation_id,
				//	page: payload.pathname,
				search: payload.search
			}
		};
		if (payload.isCustom) {
			await HttpClient.request(conf).then(response => {
				if (response && response.data) {
					window.sessionStorage.setItem('_collection', JSON.stringify(response.data));
				}
			});
		} else {
			let response = await HttpClient.request(conf);
			return Promise.resolve(response);
		}
	}
};

// get cities by country
const getCitiesByCountry = async (countryCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	try {
		const conf = {
			url:
				determineApiUrlEndpoint() +
				`/getCitiesByCountry?locale=${intl.get(
					'SystemConfig.Locale'
				)}&countryCode=${countryCode}&ip=${userIpAddress()}&id=${getUserId()}&site=${
					hostName.fullDomainName
				}&sub=${getSubFolder}&ver=${
					config.version
				}&session_id=${getClientSessionId()}&updatedAction=true`,
			method: 'GET',
			cache: true,
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: 'dropdown',
				controlName: 'Get Cities By Country',
				time: new Date().getTime(),
				event: 'selection change',
				value: countryCode,
				correlation_id,
				page: payload.pathname,
				search: payload.search
			}
		};
		let response = await HttpClient.request(conf);
		response.data = removeDuplicatesFromResponse(response.data);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

const getCitiesByCountryAndPostalCode = async (countryCode, postalCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	try {
		const conf = {
			url:
				determineApiUrlEndpoint() +
				`/getCitiesByCountryAndPostalCode?locale=${intl.get(
					'SystemConfig.Locale'
				)}&countryCode=${countryCode}&postalCode=${postalCode}&ip=${userIpAddress()}&id=${getUserId()}&site=${
					hostName.fullDomainName
				}&sub=${getSubFolder}&ver=${config.version}&session_id=${getClientSessionId()}`,
			method: 'GET',
			cache: true,
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: 'text',
				controlName: 'Get Cities By Country And PostalCode',
				time: new Date().getTime(),
				event: 'search',
				value: postalCode,
				correlation_id,
				page: payload.pathname,
				search: payload.search
			}
		};
		let response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

// choose number page get cities for given state
const getCitiesByCountryAndRegion = async (countryCode, regionCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	try {
		const conf = {
			url:
				determineApiUrlEndpoint() +
				`/getCitiesByCountryAndRegion?locale=${intl.get(
					'SystemConfig.Locale'
				)}&countryCode=${countryCode}&regionCode=${regionCode}&ip=${userIpAddress()}&id=${getUserId()}&site=${
					hostName.fullDomainName
				}&sub=${getSubFolder}&ver=${
					config.version
				}&session_id=${getClientSessionId()}&updatedAction=true`,
			method: 'GET',
			cache: true,
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: 'text',
				controlName: 'Get Cities By Country And RegionCode',
				time: new Date().getTime(),
				event: '',
				value: regionCode,
				correlation_id,
				page: payload.pathname,
				search: payload.search
			}
		};
		let response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

// when user selects canada australia or US, we have states and provinces
// this endpoint gets that data
const getRegionsByCountry = async (countryCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	try {
		const url = `${determineApiUrlEndpoint()}/getRegionsByCountry?countryCode=${countryCode}&locale=${intl.get(
			'SystemConfig.Locale'
		)}&ip=${userIpAddress()}&id=${getUserId()}&site=${
			hostName.fullDomainName
		}&sub=${getSubFolder}&ver=${
			config.version
		}&actionName=Search_Areacode&session_id=${getClientSessionId()}&updatedAction=true`;

		const conf = {
			url,
			method: 'GET',
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			cache: true,
			eventData: {
				controlType: 'text',
				controlName: 'Get Regions for Country',
				time: new Date().getTime(),
				event: 'click',
				value: countryCode,
				correlation_id,
				page: payload.pathname,
				search: payload.search
			}
		};

		const response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

const getNumbersByAreaCode = async (countryCode, areaCode, payload) => {
	try {
		window.sessionStorage.removeItem('pcity');
		let response = await getCitiesByAreaCode(countryCode, areaCode, payload);
		if (response.data && response.data.phone_numbers) {
			window.sessionStorage.setItem('pcity', response.data.phone_numbers[0].pcity_group);
			return Promise.resolve(response);
		}
	} catch (error) {
		return Promise.reject(error);
	}
};
// this lambda returns the first city that is found for the given areacode
const getCitiesByAreaCode = async (countryCode, areaCode, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';

	try {
		const url = `${determineApiUrlEndpoint()}/getNumbersByAreaCodeOrCity?countryCode=${countryCode}&areaCodeOrCity=${areaCode}&count=${intl.get(
			'SystemConfig.TotalPhoneNumbers'
		)}&ip=${userIpAddress()}&id=${getUserId()}&site=${
			hostName.fullDomainName
		}&sub=${getSubFolder}&ver=${
			config.version
		}&actionName=Search_Areacode&session_id=${getClientSessionId()}&updatedAction=true`;

		const conf = {
			url,
			method: 'GET',
			payload: { ...payload, correlation_id },
			pushAuditlogs: true,
			eventData: {
				controlType: 'text',
				controlName: 'Get City for Area Code',
				time: new Date().getTime(),
				event: 'click',
				value: areaCode,
				correlation_id,
				page: payload.pathname,
				search: payload.search
			}
		};

		const response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

const getNumbersByCityInternational = async (cityName, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	const conf = {
		url: `${determineApiUrlEndpoint()}/getNumbersByCityInternational?cityName=${cityName}&count=${intl.get(
			'SystemConfig.TotalPhoneNumbers'
		)}
 		&ip=${userIpAddress()}
		&id=${getUserId()}
		&site=${hostName.fullDomainName}
		&sub=${getSubFolder}
		&ver=${config.version}&session_id=${getClientSessionId()}&updatedAction=true`,
		method: 'GET',
		cache: true,
		payload,
		correlation_id,
		pushAuditlogs: true
	};

	try {
		const response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const reserveSelectedNumber = async (phoneNumber, payload) => {
	const correlation_id = v4();
	hostName = getHostNameData();
	const getSubFolder =
		hostName.subfolders && hostName.subfolders[0] ? hostName.subfolders[0] : 'none';
	const conf = {
		url:
			determineApiUrlEndpoint() +
			`/reserveNumber?phoneNumber=${phoneNumber}&reserveDuration=${
				config.reserveNumberDuration
			}&ip=${userIpAddress()}&id=${getUserId()}&site=${
				hostName.fullDomainName
			}&sub=${getSubFolder}&ver=${
				config.version
			}&session_id=${getClientSessionId()}&updatedAction=true`,
		method: 'PUT',
		payload: { ...payload, correlation_id: correlation_id },
		eventData: {
			controlName: 'Reserve Number',
			time: new Date().getTime(),
			event: '',
			value: phoneNumber,
			correlation_id: correlation_id,
			page: payload.pathname,
			search: payload.search
		},
		pushAuditlogs: true
	};

	try {
		const response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};
export const services = {
	getRegionsByCountry,
	getCitiesByCountry,
	getCitiesByCountryAndRegion,
	getCitiesByCountryAndPostalCode,
	getNumbersByAreaCode,
	getNumbersByCityInternational,
	reserveSelectedNumber,
	getOfferCodeDetails
};
