import styled from 'styled-components';

const Container = styled.div`
	margin-top: 30px;
`;
const FlexRow = styled.div`
	display: flex;
	p {
		font-size: 13px;
	}
	select {
	}
`;
const StyledInfo = styled.p`
	font-size: 13px;
	margin-top: 5px;
	margin-bottom: 5px;
	.info {
		display: inline-block;
		padding: 0px 3px 3px 3px;
		color: #fff;
		font-size: 18px;
		font-family: Karolina Lach;
		border-radius: 50%;
		border: solid 0px #000;
		width: 25px;
		height: 25px;
		font-weight: bolder;
		text-align: center;
		font-style: italic;
		background-color: #000;
		float: left;
		margin-right: 5px;
	}
`;
export { Container, FlexRow, StyledInfo };
