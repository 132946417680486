import React from 'react';
import { StyledFooter } from './FooterStyles';
import intl from 'react-intl-universal';
const Footer = () => {
	return (
		<StyledFooter id='footer'>
			&copy; {new Date().getFullYear()} {intl.getHTML('MainFooter')}
		</StyledFooter>
	);
};

export default Footer;
