import { HttpClient } from 'lib/funnel-auditor';
import { v4 } from 'uuid';
import config from 'systemconfig/index';
import { getUserId, getClientSessionId, determineApiUrlEndpoint, isLocalHost } from 'util/Common';
import { userIpAddress, GACookieCheck } from 'util/Cookie';
import intl from 'react-intl-universal';
import { postEvents } from 'lib/funnel-auditor/store/tracker/actions/';
export const verificationCheq = async encryptedMessage => {
	if (GACookieCheck()) {
		return;
	}

	const correlation_id = v4();
	let hostName = isLocalHost()
		? intl.get('SystemConfig.HostName')
		: window && window.location && window.location.hostname;

	const payload = {
		message: encryptedMessage,
		ip: userIpAddress(),
		id: getUserId(),
		site: hostName,
		ver: config.version,
		session_id: getClientSessionId(),
		correlation_id: correlation_id,
		userAgent: navigator.userAgent
	};
	const conf = {
		url: determineApiUrlEndpoint() + `/verificationcheq`,
		method: 'POST',
		data: payload
	};

	try {
		await HttpClient.request(conf).then(response => {
			sessionStorage.setItem('cheqID', response.data.id);

			if (response.data.navigate && response.data.threat && response.data.threat > 0) {
				const path = 'https://'
					.concat(intl.get('SystemConfig.HostName'))
					.concat(response.data.navigate);
				window.location.href = path;
			}
		});
	} catch (error) {}
};

export const captchaVerification = async solution => {
	let hostName = isLocalHost()
		? intl.get('SystemConfig.HostName')
		: window && window.location && window.location.hostname;

	const payload = {
		ip: userIpAddress(),
		solution: solution,
		id: getUserId(),
		site: hostName,
		ver: config.version,
		session_id: getClientSessionId(),
		userAgent: navigator.userAgent
	};

	const conf = {
		url: determineApiUrlEndpoint() + `/captchaverification`,
		method: 'POST',
		data: payload
	};

	try {
		const response = await HttpClient.request(conf);
		return Promise.resolve(response);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const captureSessionTimeout = async payload => {
	try {
		const conf = {
			url: determineApiUrlEndpoint().concat('/auditor'),
			pushAuditlogs: true,
			eventData: {
				id: getUserId(),
				session_id: getClientSessionId(),
				controlType: 'sessionTimeout',
				controlName: 'sessionTimeout',
				time: new Date().getTime(),
				event: 'action',
				value: `user session timeout after ${config.sessionTimeout.toString()} mins`,
				page: payload.pathname,
				search: payload.search
			}
		};
		setTimeout(() => {
			const store = window['store'];
			store.dispatch(postEvents(conf));
		}, 300);
	} catch {}
};

export const services = {
	verificationCheq,
	captchaVerification,
	captureSessionTimeout
};
