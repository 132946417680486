import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledH3 } from 'components/StyledComponents';
import { Container, FlexRow, AreaCodeInput } from './AreaCodeSearchStyles';
import intl from 'react-intl-universal';

const AreaCodeSearch = ({ searchHandler, loadingPhoneNumbers, disableContinueButton }) => {
	let location = useLocation();
	const [searchTerm, setSearchTerm] = useState('');

	const handleChange = event => {
		let numericOnly = event.target.value.replace(/\D/, '');
		setSearchTerm(numericOnly);
		disableContinueButton(true);

		if (numericOnly.length === 3) {
			handleSearch(numericOnly);
		}
	};

	const handleSearch = numericOnly => {
		//if (searchTerm.length != 3) return;
		const { pathname, search } = location;
		searchHandler({ areaCode: numericOnly, payload: { pathname, search } });
	};

	return (
		<Container>
			<StyledH3 hideOnSmall>{intl.get('ChooseNumber.AreaCodeSearch.Header')}</StyledH3>
			<FlexRow>
				<AreaCodeInput
					name={'areacodeInput'}
					value={searchTerm}
					type='tel'
					onChange={handleChange}
					maxLength={3}
					//handleEnter={handleSearch}
					disabled={loadingPhoneNumbers}
				/>
			</FlexRow>
		</Container>
	);
};

export default AreaCodeSearch;
