import { getHostNameData } from './Common';
const localizationLookup = () => {
	const hostName = getHostNameData();
	let urlName =
		hostName.topLevelDomain.length > 0
			? hostName.domain.concat('.').concat(hostName.topLevelDomain.join('.'))
			: hostName.domain;
	let localeItem;
	// if (process.env.NODE_ENV) {
	// 	console.log('I am in debug', process.env.NODE_ENV);
	// }
	// console.log(urlName);

	switch (urlName) {
		case 'efax.com': {
			localeItem = fileLookup.efax;
			if (hostName.subfolders && hostName.subfolders[0]) {
				switch (hostName.subfolders[0].toLowerCase()) {
					case 'en': {
						localeItem = fileLookup.efaxEN;
						break;
					}
					case 'ca': {
						localeItem = fileLookup.efaxCA;
						break;
					}
					// case 'uk': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'de': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'it': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'nl': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'fr': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'es': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					// case 'eu': {
					// 	localeItem = fileLookup.efaxUK;
					// 	break;
					// }
					default: {
						localeItem = fileLookup.efax;
						break;
					}
				}
			}
			break;
		}
		case 'efax.ca': {
			localeItem = fileLookup.efaxCA;
			break;
		}
		case 'myfax.com': {
			localeItem = fileLookup.myfax;
			break;
		}
		case 'metrofax.com': {
			localeItem = fileLookup.metrofax;
			break;
		}
		case 'efax.co.jp': {
			localeItem = fileLookup.efaxJP;
			break;
		}
		case 'jfax.com': {
			localeItem = fileLookup.jfax;
			break;
		}
		default: {
			localeItem = fileLookup.efax;
			break;
		}
	}

	return {
		domain: 'default',
		localeItem: { default: localeItem },
		urlName: urlName
	};
};

const fileLookup = {
	// @ts-ignore
	efax: require('./localization/domestic/efax.json'),
	// @ts-ignore
	metrofax: require('./localization/domestic/metrofax.json'),
	// @ts-ignore
	myfax: require('./localization/domestic/myfax.json'),
	// @ts-ignore
	efaxCA: require('./localization/domestic/efaxCanada.json'),
	// @ts-ignore
	// efaxUK: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxFR: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxDE: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxES: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxEU: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxIT: require('./localization/emea/efaxUK.json'),
	// // @ts-ignore
	// efaxNL: require('./localization/emea/efaxUK.json'),
	// @ts-ignore
	efaxEN: require('./localization/asia/EnEfax.json'),
	// @ts-ignore
	efaxJP: require('./localization/asia/efaxJP.json'),
	// @ts-ignore
	jfax: require('./localization/asia/jfax.json'),
};

export { localizationLookup };
