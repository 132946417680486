import Name from '..';
import * as uiActionTypes from '../actionTypes';
import { createReducer, createHandlers } from '../../helpers';

const initialState = {
	serverError: false
};
const typeKeyMap = [{ type: uiActionTypes.SETSERVERERROR, stateKey: 'serverError' }];
const handlers = createHandlers({ typeKeyMap, Name, initialState });

export default createReducer(initialState, handlers);
