import styled from 'styled-components';
import { device } from 'styles/mediaquery';
import intl from 'react-intl-universal';

const HeaderContainer = styled.div`
	display: flex;
	// flex-wrap: wrap;
	// flex-direction: column;
	// align-content: space-between;
	justify-content: center;
	position: relative;
	height: 80px;
	margin: auto;
	max-width: 1170px;
	padding: 0 15px;
	@media ${device.desktopL} {
		width: auto;
	}
	@media ${device.mobile} {
		width: auto;
		padding: 0;
	}
	@media ${device.tablet} {
		width: auto;
	}
`;

const Anchor = styled.div`
	outline: none;
	border: none;
`;

const HeaderLeft = styled.div`
	display: flex;
	text-align: center;
	img {
		width: 80%;
		@media ${device.mobile} {
			margin-top: 25px;
			width: 60%;
		}
	}
	span {
		margin-top: 20px;
		margin-left: 20px;
		@media ${device.mobile} {
			display: block;
			margin-top: 25px;
			margin-left: 0px;
		}
	}
`;
const HeaderRight = styled.div`
	display: block;
	position: absolute;
	right: 37px;
	color: ${props =>
		intl.get('MainHeader.SupportTextColor')
			? intl.get('MainHeader.SupportTextColor')
			: '#000'} !important;
	background: ${props =>
		intl.get('MainHeader.SupportBackgroundColor')
			? intl.get('MainHeader.SupportBackgroundColor')
			: '#000'};
	font-size: 14px;
	padding: 10px;
	@media ${device.mobile} {
		right: 0;
		height: 39px;
		padding: 10px;
		width: 100%;
		text-align: center;
	}
`;

const HeaderRightImage = styled.div`
	display: block;
	position: absolute;
	right: 37px;
	font-size: 14px;
	padding: 10px;
	@media ${device.mobile} {
		right: 0;
		height: 39px;
		padding: 10px;
		width: 100%;
		text-align: center;
	}
`;

const Header = styled.span`
	color: #006697;
`;

const HeaderNumber = styled.span`
	font-weight: bold;
`;

export { HeaderContainer, HeaderLeft, HeaderRight, Anchor, HeaderRightImage, Header, HeaderNumber };
