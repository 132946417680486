import React from 'react';
import PropTypes from 'prop-types';
import withChange from '../../hocs/withChange';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import SelectSearch from 'react-select-search';
import './plan-dropdown.css';
import intl from 'react-intl-universal';

const SelectablePlanDropDown = props => {
	let { name, changeHandler, options, placeholder, searchable = false, ...rest } = props;

	const handleOptionChange = selection => {
		changeHandler(selection);
	};

	function renderValue(valueProps, snapshot, className) {
		let name;
		let duration;
		let strikeThru;
		if (snapshot && snapshot.option && snapshot.option.customPlanDropDown) {
			const custom = snapshot.option.customPlanDropDown;
			name =
				custom.planName +
				' ' +
				intl.get('PlanSummary.DueTotalAmount', {
					amount: custom.subscription_fee,
					taxSymbol: custom.taxSymbol,
					currency: custom.currency
				});
			duration = custom.planDuration;
			strikeThru = snapshot.option.strike;
		}
		return (
			<>
				<span {...valueProps} className={className}>
					{name}
					<span className='per'>{duration}</span>
					<span className='strike'>{strikeThru}</span>
				</span>
			</>
		);
	}

	function renderOption(props, option, snapshot, className) {
		let name;
		let duration;
		let strikeThru;
		if (option && option.customPlanDropDown) {
			const custom = option.customPlanDropDown;
			name =
				custom.planName +
				' ' +
				intl.get('PlanSummary.DueTotalAmount', {
					amount: custom.subscription_fee,
					taxSymbol: custom.taxSymbol,
					currency: custom.currency
				});
			duration = custom.planDuration;
			strikeThru = option.strike;
		}
		return (
			<button {...props} className={className} type='button'>
				<span>
					{name}
					<span className='per'>{duration}</span>
					<span className='strike'>{strikeThru}</span>
				</span>
			</button>
		);
	}

	const singleOfferCode = options => {
		let name;
		let duration;
		let strikeThru;
		if (options && options.customPlanDropDown) {
			const custom = options.customPlanDropDown;
			name =
				custom.planName +
				' ' +
				intl.get('PlanSummary.DueTotalAmount', {
					amount: custom.subscription_fee,
					taxSymbol: custom.taxSymbol,
					currency: custom.currency
				});
			duration = custom.planDuration;
			strikeThru = options.strike;
		}
		return (
			<div className='select-customplans__value single-item'>
				<span className={'select-customplans__input single-item'}>
					{name}
					<span className='per'>{duration}</span>
					<span className='strike'>{strikeThru}</span>
				</span>
			</div>
		);
	};

	return (
		<>
			{options.length === 1 ? (
				singleOfferCode(options[0])
			) : (
				<SelectSearch
					className={'select-customplans'}
					options={options}
					renderOption={renderOption}
					renderValue={renderValue}
					onChange={handleOptionChange}
					search={searchable}
					emptyMessage={intl.get('ValidationMessages.SearchNotFound')}
					required
					placeholder={placeholder}
					autoComplete='new'
					printOptions='on-focus'
					{...rest}
				/>
			)}
		</>
	);
};

SelectablePlanDropDown.propTypes = {
	//options: PropTypes.array.isRequired,
	changeHandler: PropTypes.func.isRequired
};

export default WithConnect(withChange(WithCorrelationId(SelectablePlanDropDown)));
