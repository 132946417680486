import { v4 as uuidv4 } from 'uuid';
import intl from 'react-intl-universal';
import { parseDomain, ParseResultType } from 'parse-domain';

export const createSetter = (name, props) =>
	props.map((prop) => {
		const type = `${name}/${prop}`;
		const setter = (payload) => {
			return {
				type,
				payload: { [prop]: payload === undefined ? [] : payload },
			};
		};
		setter.toString = () => type;
		return setter;
	});

export const createReducer =
	(defaultState, handlers) =>
	(state = defaultState, action) => {
		if (handlers[action.type]) return handlers[action.type](state, action.payload);
		return state;
	};

export const createSelectors = (name, props) => props.map((prop) => (state) => state[name][prop]);

export const createHandlers = ({ typeKeyMap, Name, initialState }) =>
	typeKeyMap.reduce((accumlator, nextValue) => {
		accumlator[`${Name}/${nextValue.type}`] = (state = initialState, payload) => {
			const { [nextValue.type]: value } = payload;
			return {
				...state,
				[nextValue.stateKey]: value,
			};
		};
		return accumlator;
	}, {});

export const generateNewSessionId = () => {
	if (!window.sessionStorage.getItem('clientSessionId')) {
		const clientSessionId = uuidv4();
		window.sessionStorage.setItem('clientSessionId', clientSessionId);
		return window.sessionStorage.getItem('clientSessionId');
	} else {
		return window.sessionStorage.getItem('clientSessionId');
	}
};

export const getUserId = () => {
	if (!window.localStorage.getItem('userId')) {
		window.localStorage.setItem('userId', uuidv4());
		return window.localStorage.getItem('userId');
	} else {
		return window.localStorage.getItem('userId');
	}
};

export const determineApiUrlEndpoint = () => {
	const hostName = getHostNameData().subDomain;
	let url = intl.get('SystemConfig.Endpoints.QA.APIGW');
	if (hostName.includes('secure')) {
		url = intl.get('SystemConfig.Endpoints.Production.APIGW');
	}
	return url;
};

const getHostNameData = () => {
	let isLocalHost = false;
	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === '127.0.0.1' ||
		window.location.hostname === ''
	) {
		isLocalHost = true;
	}
	const hostName = isLocalHost
		? intl.get('SystemConfig.HostName')
		: window && window.location && window.location.hostname;

	const parseResult = parseDomain(hostName);

	if (parseResult.type === ParseResultType.Listed) {
		const { subDomains, domain, topLevelDomains } = parseResult;
		return {
			subDomain: subDomains,
			domain: domain,
			topLevelDomain: topLevelDomains,
			fullDomainName: subDomains
				.concat(domain)
				.join('.')
				.concat('.')
				.concat(topLevelDomains.join('.')), //domain.concat('.').concat(topLevelDomains.join('.'))
		};
	} else {
		return {
			subDomain: [],
			domain: window.location.host,
			topLevelDomain: [],
			fullDomainName: window.location.host,
		};
	}
};
