import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CustomEvent from '../event';

const withBlur = WrappedComponent =>
	withRouter(props => {
		const {
			// props coming from WithConnect Hoc
			addEvents,
			resetEvents,
			resetServerError,
			postEvents,

			// props coming from the application( the place where the wrapped component is used )
			handler,
			text,

			// prop coming from WithRouter(provides information about page)
			location,
			staticContext,

			// some additional props specific to this Hoc component
			handleEnter,
			maskType,
			flushEvents,
			pushEvents,
			doNotLog,

			...rest
		} = props;

		const blurHandler = event => {
			const { target } = event;
			if (maskType) target.maskType = maskType;
			const customEvent = new CustomEvent({
				target,
				event,
				location
			});
			if (!doNotLog) addEvents(customEvent);
			if (pushEvents) postEvents();
			if (flushEvents) resetEvents();
			if (handler) handler(event);
		};

		const enterHandler = ({ event, blurHandler, handleEnter }) => {
			if (event.which === 13 || event.which === undefined) {
				blurHandler(event);
				handleEnter(event);
			}
		};
		return (
			<WrappedComponent
				{...rest}
				enterHandler={event => {
					if (handleEnter) enterHandler({ event, blurHandler, handleEnter });
				}}
				blurHandler={blurHandler}
			/>
		);
	});

withBlur.propTypes = {
	addEvents: PropTypes.func.isRequired,
	handler: PropTypes.func,
	handleEnter: PropTypes.func
};

withBlur.defaultProps = {
	handleEnter: () => {},
	handler: () => {}
};

export default withBlur;
