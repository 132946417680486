import styled from 'styled-components';

const Container = styled.div`
	margin-top: 20px;
`;
const FlexRow = styled.div`
	display: flex;
	margin-top: 5px;
	p {
		font-size: 13px;
	}
	select {
		margin-left: 0.5rem;
	}
`;
export { Container, FlexRow };
