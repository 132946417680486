import React from 'react';
import PropTypes from 'prop-types';
import WithClick from '../../hocs/withclick';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';

const Button = props => {
	const { clickHandler, mouseDownHandler, text, img, ...rest } = props;
	delete rest['data-isReserveReady'];
	return (
		<button onClick={clickHandler} onMouseDown={mouseDownHandler} type='button' {...rest}>
			{img ? <>{img}</> : null}
			{text}
		</button>
	);
};

Button.propTypes = {
	text: PropTypes.any.isRequired,
	clickHandler: PropTypes.func.isRequired
};

export default WithConnect(WithClick(WithCorrelationId(Button)));
