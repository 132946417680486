export default class Event {
	constructor({ target, event, location }) {
		const correlation_id = target.getAttribute ? target.getAttribute('correlation_id') : '';
		this.time = new Date().getTime();
		this.event = event.type === 'keyup' ? 'enter' : event.type;
		this.controlType = target.type;
		this.controlName = target.name;
		if (correlation_id) this.correlation_id = correlation_id;
		this.page = location.pathname;
		this.queryParams = location.search;
		this.value = this.maskInput({ value: this.getValue({ target }), maskType: target.maskType });
	}

	maskInput({ value, maskType }) {
		if (maskType) {
			switch (maskType) {
				case 'textMask':
					return value.replace(/./g, 'X');
				default:
					return value;
			}
		}
		return value;
	}

	getValue({ target }) {
		switch (target.type) {
			case 'checkbox':
				return target.checked ? 'checked' : 'unchecked';
			case 'select':
				return target.value.reduce((accumlator, nextValue) => {
					return `${accumlator}${nextValue[target.displayField]} ,`;
				}, '');
			default:
				return target.value;
		}
	}
}
