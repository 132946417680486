import axios from 'axios';
import SimpleCrypto from 'simple-crypto-js';
import * as TrackerActionTypes from '../actionTypes';
import {
	createSetter,
	generateNewSessionId,
	getUserId,
	determineApiUrlEndpoint
} from '../../helpers';
import { fullGeoInfo, marketingCookies } from 'util/Cookie';
import Name from '..';

const findReferrer = require('find-referrer');
export const [
	resetEvents,
	setId,
	setIpAddress,
	setSiteVersion,
	setSiteUrl,
	setUserAgent
] = createSetter(Name, [
	TrackerActionTypes.RESETEVENTS,
	TrackerActionTypes.ID,
	TrackerActionTypes.IPADDRESS,
	TrackerActionTypes.SITEVERSION,
	TrackerActionTypes.SITEURL,
	TrackerActionTypes.USERAGENT
]);

export const addEvents = payload => {
	return {
		type: `${Name}/${TrackerActionTypes.ADDEVENTS}`,
		payload
	};
};

export const postEvents = (payload = {}) => (dispatch, getState) => {
	const { url, event, page, queryParams, eventData } = payload;
	const { id, userAgent, siteUrl, siteVersion, ipAddress } = getState()[Name];
	const currentDate = new Date();
	const secretKeyString = `${currentDate.getUTCFullYear()}-j2Funnel`;
	const simpleCrypto = new SimpleCrypto(secretKeyString);
	let apiUrl = determineApiUrlEndpoint();

	const state = eventData
		? {
				id: getUserId(),
				userAgent,
				refererUrl: findReferrer() || window.location.href,
				siteUrl,
				serverVersion: siteVersion,
				session_id: generateNewSessionId(),
				geoInfo: fullGeoInfo(),
				marketingCookies: marketingCookies(),
				ipAddress,
				events: [{ ...eventData }]
		  }
		: { refererUrl: window.location.href, ...getState()[Name] };

	if (state && state.events.length > 0) {
		const encryptedString = simpleCrypto.encrypt(JSON.stringify(state));
		axios({
			method: 'post',
			url: url || apiUrl.concat('/auditor'),
			data: encryptedString,
			config: { headers: { 'Content-Type': 'text/html; charset=utf-8', 'Accept-Encoding': 'gzip' } }
		}).then(response => {
			return response;
		});
	}
};

export const setUserInfo = payload => (dispatch, getState) => {
	const { id, siteUrl, userAgent, ipAddress, siteVersion } = payload;
	if (id) dispatch(setId(id));
	if (siteUrl) dispatch(setSiteUrl(siteUrl));
	if (userAgent) dispatch(setUserAgent(userAgent));
	if (ipAddress) dispatch(setIpAddress(ipAddress));
	if (siteVersion) dispatch(setSiteVersion(siteVersion));
};
