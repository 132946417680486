import intl from 'react-intl-universal';

const convertStringToJson = (searchParams, itemsToRemove) => {
	const params = new URLSearchParams(searchParams.toString());
	if (itemsToRemove) {
		for (const val of itemsToRemove) {
			params.delete(val);
		}
	}
	return (
		'{"' +
		decodeURI(params.toString()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
		'"}'
	);
};

const getOfferCodeQueryParam = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (
		queryParameters.has('offercode') &&
		queryParameters.get('offercode').toUpperCase().match('^[A-Z0-9_-]*$')
	) {
		return queryParameters.get('offercode').toString().toUpperCase();
	}
	return '';
};

const hasSearchByState = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('searchby')) {
		return queryParameters.get('searchby') === 'state';
	}
	return false;
};

const hasToggleplans = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('toggleplans') || intl.get('SystemConfig.ForceTogglePlans')) {
		return queryParameters.get('toggleplans') !== null
			? queryParameters.get('toggleplans') === 'true'
			: intl.get('SystemConfig.ForceTogglePlans');
	}
};

const has1col = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('1col')) {
		return true;
	}
	return false;
};

const hasAmt = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('amt')) {
		return true;
	}
	return false;
};

const hasNewSinglePlanDesign = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('snl') && queryParameters.get('snl') === 'true') {
		return true;
	}
	return false;
};

const hasIndustryPage = () => {
	const queryParameters = new URLSearchParams(document.location.search.toLowerCase());
	if (queryParameters.has('i') && queryParameters.get('i') === 'true') {
		return true;
	}
	return false;
};
export {
	convertStringToJson,
	getOfferCodeQueryParam,
	hasSearchByState,
	has1col,
	hasToggleplans,
	hasAmt,
	hasNewSinglePlanDesign,
	hasIndustryPage,
};
