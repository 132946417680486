export default {
	fallbackCookie:
		'ip=204.11.170.48&areacode=323+213&city=LOSANGELES&state=CA&country=US&timezone=PST&bandwidth=5000&fallback=true',
	phoneNumbersPerPage: 6,
	abandonedEmailDelay: 60,
	version: '7.0.6',
	sessionTimeout: 20,
	reserveNumberDuration: 20,
	defaultCacheAge: 7200, //in mins = 5days
	defaultCacheAgeForPhoneNumbers: 1, //in mins
	ignoreCache: false,
};
