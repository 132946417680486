import styled from 'styled-components';

const Container = styled.span`
	img {
		width: 20px;
		margin-bottom: 10px;
	}
`;

export { Container };
