import React, { useState, useEffect } from 'react';
import { StyledH3 } from 'components/StyledComponents';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import { Container, FlexRow } from './StateSelectorStyles';
import intl from 'react-intl-universal';
import { SearchableSelect } from 'lib/funnel-auditor';

const StateSelector = ({
	selectedCountry,
	selectedSearchBy,
	handleStateSelect,
	loadingCities,
	citiesLoaded,
	onStatesLoadedForCountry,
	disabledControl
}) => {
	let location = useLocation();
	const [states, setStates] = useState([]);
	const [selectedStatevalue, setSelectedStatevalue] = useState(''); //useHistoryState('selectedState', '');
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);

	const onStateSelect = event => {
		const { pathname, search } = location;
		//removeHistoryState('selectedCity;selectedState');
		setSelectedStatevalue(event);
		handleStateSelect({ stateCode: event, payload: { pathname, search } });
	};

	const reload = () => {
		const { pathname, search } = location;
		handleStateSelect({ stateCode: selectedStatevalue, payload: { pathname, search } });
	};

	useEffect(() => {
		setLoading(true);
		try {
			let response = selectedCountry === 'US' ? intl.get('StateList') : intl.get('ProvinceList');
			setStates(response);
			setLoading(false);
			setFailed(false);
			onStatesLoadedForCountry();
		} catch (error) {
			setLoading(false);
			setFailed(false);
			onStatesLoadedForCountry();
		}

		if (selectedStatevalue.length > 0) {
			reload();
		}
	}, [selectedCountry]);

	const placeholderText = intl
		.get('ChooseNumber.StateSearch.PartialTitle')
		.concat(selectedSearchBy);
	return (
		<Container>
			<StyledH3 hideOnSmall>{placeholderText}</StyledH3>
			{loading ? (
				<LoadingSpinner />
			) : (
				<FlexRow>
					<SearchableSelect
						name={'stateDropdown'}
						placeholder={placeholderText}
						handler={onStateSelect}
						options={states}
						value={selectedStatevalue}
						disabled={disabledControl || (loadingCities && !citiesLoaded)}
					/>
				</FlexRow>
			)}
		</Container>
	);
};

export default StateSelector;
