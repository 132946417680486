import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { services } from 'services/ChooseNumberService';
import { StyledH3 } from 'components/StyledComponents';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { Container, FlexRow, FailedResults } from './CitySelectorStyles';
import intl from 'react-intl-universal';
import { SearchableSelect } from 'lib/funnel-auditor';
import { determineContactInfo, appendDividerToDropDown } from 'util/Common';

const CitySelector = ({
	selectedState,
	selectedCountry,
	selectedSearchBy,
	handleCitySelect,
	onCitiesLoadedForState,
	onCitiesLoadedForCountry,
	loadingPhoneNumbers,
	phoneNumbersLoaded,
	disabledControl,
	handleDisabledControl,
}) => {
	let location = useLocation();
	const [cities, setCities] = useState([]);
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);
	const [selectedCity, setSelectedCity] = useState('');
	const [SingleCity, setSingleCity] = useState(false);

	const onCitySelect = (event) => {
		setSelectedCity(event);
		const { pathname, search } = location;
		handleCitySelect({ cityCode: event, payload: { pathname, search } });
	};

	useEffect(() => {
		const getCitiesByCountry = async () => {
			if (selectedSearchBy === 'City') {
				setLoading(true);
				try {
					handleDisabledControl(true);
					const { pathname, search } = location;
					const response = intl.get('SystemConfig.PreventCitySearch')
						? ''
						: await services.getCitiesByCountry(selectedCountry, { pathname, search });

					if (response && response.data.length == 1) {
						setSingleCity(true);
						onCitySelect(response.data[0].value);
					} else {
						setSingleCity(false);
						let preferredCities = []; //cities that are shown first in the dropdown
						let remainingCities = [];
						let result = [];

						const preferredCitiesList = Object.assign([], intl.get('PreferredCities'));
						const manualCityList = Object.assign([], intl.get('ManualCityList'));

						if (intl.get('SystemConfig.PreventCitySearch')) {
							//this will only return the PreferredCities
							preferredCitiesList.forEach((pcity) => {
								preferredCities.push(pcity);
							});

							if (manualCityList.length > 0 && preferredCitiesList.length > 0) {
								preferredCities.push(appendDividerToDropDown());
							}

							manualCityList.forEach((pcity) => {
								preferredCities.push(pcity);
							});
							setCities(preferredCities);
						} else {
							const results = response && response.data;
							if (preferredCitiesList.length === 0) {
								setCities(results);
							} else {
								for (let i = 0; i < preferredCitiesList.length; i++) {
									preferredCities[i] = results.filter(
										(data) => data.value.search(preferredCitiesList[i].value) !== -1
									); //filtering prefered cities
									result = result.concat(preferredCities[i]);
								}

								result = result.concat(appendDividerToDropDown());

								remainingCities = results;
								for (let i = 0; i < preferredCitiesList.length; i++) {
									remainingCities = remainingCities.filter(
										(data) => data.value.search(preferredCitiesList[i].value) === -1
									); //filtering remaining cities
								}
								const uniqueNames = Array.from(new Set(remainingCities)); //removing duplicate values
								result = result.concat(uniqueNames);
								setCities(result);
							}
						}
					}
					setFailed(false);
					setLoading(false);
					onCitiesLoadedForCountry();
					handleDisabledControl(false);
				} catch (error) {
					setFailed(true);
					setLoading(false);
					onCitiesLoadedForCountry();
					handleDisabledControl(false);
				}
			}
		};
		getCitiesByCountry();
	}, [selectedCountry, selectedSearchBy]);

	useEffect(() => {
		const getCitiesByCountryAndRegion = async () => {
			setLoading(true);
			try {
				handleDisabledControl(true);
				const { pathname, search } = location;
				const response = await services.getCitiesByCountryAndRegion(
					selectedCountry,
					selectedState,
					{
						pathname,
						search,
					}
				);
				setLoading(false);
				setFailed(false);
				setCities(response.data);
				onCitiesLoadedForState();
				handleDisabledControl(false);
			} catch (error) {
				setFailed(true);
				setLoading(false);
				onCitiesLoadedForState();
				handleDisabledControl(false);
			}
		};
		if (selectedState !== '') getCitiesByCountryAndRegion();
	}, [selectedState]);

	return (
		<Container>
			{loading ? (
				<LoadingSpinner />
			) : failed ? (
				<FailedResults>
					{intl.get('ChooseNumber.FailedResults.NoResults', {
						contact: determineContactInfo().Contact,
					})}
				</FailedResults>
			) : !SingleCity ? (
				<>
					<StyledH3 hideOnSmall>{intl.get('ChooseNumber.CitySearch.Header')}</StyledH3>
					<FlexRow>
						<SearchableSelect
							name={'cityDropdown'}
							placeholder={intl.get('ChooseNumber.CitySearch.DropdownTitle')}
							handler={onCitySelect}
							options={cities}
							value={selectedCity}
							disabled={disabledControl || (loadingPhoneNumbers && !phoneNumbersLoaded)}
						/>
					</FlexRow>
				</>
			) : null}
		</Container>
	);
};

export default CitySelector;
