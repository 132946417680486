import Name from '..';
import * as TrackerActionTypes from '../actionTypes';
import { createReducer, createHandlers, generateNewSessionId, getUserId } from '../../helpers';
import { userIpAddress, fullGeoInfo, marketingCookies } from 'util/Cookie';
import { getHostNameData } from 'util/Common';
import config from 'systemconfig';

const findReferrer = require('find-referrer');
const hostData = getHostNameData();
const siteUrl = hostData.fullDomainName.concat('/').concat(hostData.subfolders.join('/'));

const initialState = {
	id: getUserId(),
	userAgent: '',
	refererUrl: findReferrer() || window.location.href,
	siteUrl: siteUrl,
	siteVersion: config.version,
	session_id: generateNewSessionId(),
	geoInfo: fullGeoInfo(),
	marketingCookies: marketingCookies(),
	ipAddress: userIpAddress() !== undefined ? userIpAddress() : 'Unable to locate users IP Address',
	events: []
};
const typeKeyMap = [
	{ type: TrackerActionTypes.RESETEVENTS, stateKey: 'events' },
	{ type: TrackerActionTypes.ID, stateKey: 'id' },
	{ type: TrackerActionTypes.USERAGENT, stateKey: 'userAgent' },
	{ type: TrackerActionTypes.SITEURL, stateKey: 'siteUrl' },
	{ type: TrackerActionTypes.SITEVERSION, stateKey: 'siteVersion' },
	{ type: TrackerActionTypes.IPADDRESS, stateKey: 'events' }
];
const handlers = createHandlers({ typeKeyMap, Name, initialState });

handlers[`${Name}/${TrackerActionTypes.ADDEVENTS}`] = (state = initialState, payload) => {
	return {
		...state,
		events: [...state.events, payload]
	};
};

export default createReducer(initialState, handlers);
