import styled from 'styled-components';
const Container = styled.div`
	margin-top: 30px;
	input {
		cursor: pointer;
	}
`;
const FlexRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	label {
		margin-right: 10px;
		white-space: nowrap;
		vertical-align: middle;
		cursor: pointer;
	}
	input {
		margin-right: 10px;
	}
`;
export { Container, FlexRow };
