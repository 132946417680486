import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CustomEvent from '../event';

const WithClick = WrappedComponent =>
	withRouter(props => {
		const {
			// props coming from WithConnect Hoc
			// @ts-ignore
			addEvents,
			// @ts-ignore
			postEvents,
			// @ts-ignore
			resetEvents,
			// @ts-ignore
			resetServerError,

			// prop coming from WithRouter(provides information about page)
			match,
			location,
			staticContext,

			// some additional props specific to this Hoc component
			// @ts-ignore
			pushEvents,
			// @ts-ignore
			doNotLog,
			// @ts-ignore
			id,
			// @ts-ignore
			url,
			// @ts-ignore
			closeServerErrorModal,
			// @ts-ignore
			mouseDownHandler,

			// props coming from the application( the place where the wrapped component is used )
			// @ts-ignore
			handler,
			// @ts-ignore
			text,

			...rest
		} = props;

		const clickHandler = async event => {
			const { target } = event;
			const customEvent = new CustomEvent({
				target,
				event,
				location
			});
			// @ts-ignore
			if (text) customEvent.text = text;
			if (!doNotLog) addEvents(customEvent);
			if (handler) handler(event);
			if (closeServerErrorModal) resetServerError(false);
			if (pushEvents) {
				try {
					await postEvents({ url, userId: id });
				} catch (error) {}
				resetEvents();
			}
		};
		return (
			<WrappedComponent
				text={text}
				{...rest}
				clickHandler={clickHandler}
				mouseDownHandler={event => {
					clickHandler(event);
					if (mouseDownHandler) mouseDownHandler(event);
				}}
			/>
		);
	});

WithClick.propTypes = {
	addEvents: PropTypes.func.isRequired,
	postEvents: PropTypes.func.isRequired,
	resetEvents: PropTypes.func.isRequired,
	match: PropTypes.object,
	handler: PropTypes.func,
	id: PropTypes.string.isRequired,
	// @ts-ignore
	text: PropTypes.any.Required,
	url: PropTypes.string,
	pushEvents: PropTypes.bool,
	resetServerError: PropTypes.func.isRequired,
	closeServerErrorModal: PropTypes.bool
};

WithClick.defaultValues = { pushEvents: false, url: '', closeServerErrorModal: false };
export default WithClick;
