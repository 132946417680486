import {
	Input,
	Button,
	SimpleSelect,
	Radio,
	Checkbox,
	FormikInput,
	FormikSelect,
	FormikRadio,
	FormikCheckBox
} from 'lib/funnel-auditor';
import styled from 'styled-components';
import { device } from 'styles/mediaquery';
import intl from 'react-intl-universal';

const StyledCheckbox = styled(Checkbox)``;

const StyledInput = styled(Input)`
	font-weight: 400;
	padding: 3px 5px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 36px;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 1.42857143;
	color: #666666;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

	&::placeholder {
		color: lightgrey;
	}
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
`;

// const StyledButton = styled(Button)`
// 	background: linear-gradient(to bottom, #cc0000 0%, #990000 100%);
// 	background-repeat: repeat-x;
// 	color: #ffffff;
// 	font-weight: 700;
// 	line-height: 20px;
// 	border: 1px solid #660000;
// 	border-radius: 5px;
// 	padding: 0px 12px;
// 	&:disabled {
// 		background-image: linear-gradient(to bottom, #cacaca 0%, #cacaca 100%);
// 		background-repeat: repeat-x;
// 		border-color: #cacaca;
// 		color: #eee;
// 		font-size: 16px;
// 		color: #686868;
// 		opacity: 0.65;
// 	}
// `;

const StyledContinueButton = styled(Button)`
	background: ${props => intl.get('Buttons.Color.Background')};
	background-repeat: repeat-x;
	font-weight: 700;
	color: ${props => intl.get('Buttons.Color.FontColor')};
	line-height: 20px;
	font-size: 16px;
	margin-top: 10px;
	margin-right: 10px;
	border: ${props => intl.get('Buttons.Color.Border')};
	border-radius: ${props => intl.get('Buttons.Color.BorderRadius')};
	padding: 11px 15px;
	&:disabled {
		background: linear-gradient(to bottom, #cacaca 0%, #cacaca 100%);
		background-repeat: repeat-x;
		border-color: #cacaca;
		color: #eee;
		border: none;
		font-size: 16px;
		color: #686868;
		opacity: 0.65;
	}
	@media ${device.mobile} {
		width: 100%;
	}
`;

const StyledH3 = styled.h3`
	color: #666666;
	font-size: 24px;
	font-weight: 400;

	@media ${device.tablet} {
		display: ${props => (props.hideOnSmall ? 'none' : 'block')};
	}
`;

const StyledP = styled.p`
	font-size: 14px;
	color: #555;
	a {
		font-size: 14px;
		font-weight: 400;
		padding: 5px;
		color: rgb(0, 102, 153);
		text-decoration: underline;
		margin: 30px 0px;
		&:hover,
		&:focus {
			color: #0086ca;
			text-decoration: none;
		}
		@media ${device.tablet} {
			font-size: 15px;
		}
	}
	a:last-child {
		padding-right: 0px;
	}
`;

const StyledSimpleSelect = styled(SimpleSelect)`
	font-weight: 400;
	padding: 3px 5px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 36px;
	line-height: 1.42857143;
	color: #666666;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	&::placeholder {
		color: lightgrey;
	}
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
`;

const StyledRadioButton = styled(Radio)`
	background-image: linear-gradient(to bottom, #cc0000 0%, #990000 100%);
	color: white;
	&:disabled {
		background-image: linear-gradient(to bottom, #cacaca 0%, #cacaca 100%);
		background-repeat: repeat-x;
		border-color: #cacaca;
		color: #eee;
		color: #686868;
		opacity: 0.65;
	}
`;

const StyledCreditCardTypeRadioButtons = styled(Radio)`
	font-size: 12px;
`;
const StyledSearchByRadioButtons = styled(Radio)`
	font-size: 12px;
`;
const StyledImage = styled.img`
	width: auto;
	height: auto;
`;

const StyledNavLink = styled.a`
	font-size: 14px;
	font-weight: 400;
	padding: 5px;
	color: rgb(0, 102, 153);
	text-decoration: underline;
	margin: 30px 0px;
	&:hover,
	&:focus {
		color: #0086ca;
		text-decoration: none;
	}
	@media ${device.tablet} {
		font-size: 15px;
	}
`;

const StyledSpan = styled.span`
	font-size: 14px;
	color: #7d7d7d;
	font-weight: bold;
	display: block;
	margin-bottom: 5px;
`;

const StyledSelectList = styled(SimpleSelect)`
	width: 100%;
	max-height: 100px;
	font-weight: 400;
	padding: 3px 5px;
	font-size: 18px;
	border: 1px solid rgb(204, 204, 204);
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
`;
const StyledRadio = styled(Radio)`
	font-size: 12px;
`;

const StyledFormikInput = styled(FormikInput)`
	font-weight: 400;
	padding: 3px 5px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 36px;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 1.42857143;
	color: #666666;
	background-color: #ffffff;
	background-image: none;

	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	border: ${props => (props.hasErrors ? '1px solid #990000' : '1px solid  #cccccc')};
	&::placeholder {
		color: lightgrey;
	}
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledFormikSelect = styled(FormikSelect)`
	font-weight: 400;
	padding: 3px 5px;
	font-size: 18px;
	display: block;
	width: 100%;
	height: 36px;

	font-size: 18px;
	line-height: 1.42857143;
	color: #666666;
	background-color: #ffffff;
	background-image: none;
	border: ${props => (props.hasErrors ? '1px solid #990000' : '1px solid  #cccccc')};
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	&::placeholder {
		color: lightgrey;
	}
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
	&:disabled {
		cursor: not-allowed;
	}
`;

const StyledFormikRadio = styled(FormikRadio)`
	font-size: 12px;
`;

const StyledFormikCheckBox = styled(FormikCheckBox)``;

const StyledSpanPwd = styled.span`
	margin-top: 38px;
	margin-left: 318px;
	cursor: pointer;
	position: absolute;
	@media ${device.mobile} {
		margin-left: 291px;
	}
	@media ${device.tabletL} {
		margin-left: 252px;
	}
`;

export {
	StyledInput,
	// StyledButton,
	StyledContinueButton,
	StyledH3,
	StyledSimpleSelect,
	StyledRadioButton,
	StyledImage,
	StyledP,
	StyledNavLink,
	StyledSearchByRadioButtons,
	StyledCreditCardTypeRadioButtons,
	StyledSelectList,
	StyledSpan,
	StyledRadio,
	StyledCheckbox,
	StyledFormikInput,
	StyledFormikSelect,
	StyledFormikRadio,
	StyledFormikCheckBox,
	StyledSpanPwd
};
