import React from 'react';
import {
	PanoFunnelSteps,
	PanoContainer,
	PanoSection,
	SectionConnector,
	CompleteCheckMark,
	InCompleteCheckMark
} from './PanoStyles';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';

export default withRouter(props => (
	<>
		<PanoFunnelSteps>
			<PanoContainer>
				<PanoSection>
					<div className='itemContainer'>
						{parseInt(sessionStorage.getItem('pageCount')) >= 2 ||
						props.match.path === intl.get('PageRoutes.Step2') ? (
							<CompleteCheckMark>
								<svg className='checkmark completed' viewBox='0 0 52 52'>
									<circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
									<path fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
								</svg>
							</CompleteCheckMark>
						) : (
							<InCompleteCheckMark active={parseInt(sessionStorage.getItem('pageCount')) >= 1}>
								1
							</InCompleteCheckMark>
						)}
						<div className='headerText'>{intl.getHTML('MainHeader.Step1')}</div>
					</div>
					<SectionConnector
						active={
							parseInt(sessionStorage.getItem('pageCount')) >= 2 ||
							props.match.path === intl.get('PageRoutes.Step2')
						}
					/>
					<div className='itemContainer'>
						{parseInt(sessionStorage.getItem('pageCount')) > 2 ? (
							<CompleteCheckMark>
								<svg className='checkmark ' viewBox='0 0 52 52'>
									<circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
									<path fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
								</svg>
							</CompleteCheckMark>
						) : (
							<InCompleteCheckMark active={props.match.path === intl.get('PageRoutes.Step2')}>
								2
							</InCompleteCheckMark>
						)}
						<div className='headerText'>{intl.getHTML('MainHeader.Step2')}</div>
					</div>
					<SectionConnector
						active={
							parseInt(sessionStorage.getItem('pageCount')) > 2 ||
							props.match.path === intl.get('PageRoutes.Step3') ||
							props.match.path === intl.get('PageRoutes.Step3a') ||
							props.match.path === intl.get('PageRoutes.Step3ab')
						}
					/>
					<div className='itemContainer'>
						{parseInt(sessionStorage.getItem('pageCount')) > 3 ? (
							<CompleteCheckMark>
								<svg className='checkmark ' viewBox='0 0 52 52'>
									<circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
									<path fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
								</svg>
							</CompleteCheckMark>
						) : (
							<InCompleteCheckMark
								active={
									props.match.path === intl.get('PageRoutes.Step3') ||
									props.match.path === intl.get('PageRoutes.Step3ab')
								}
							>
								3
							</InCompleteCheckMark>
						)}
						<div className='headerText'>{intl.getHTML('MainHeader.Step3')}</div>
					</div>
					<SectionConnector
						active={
							parseInt(sessionStorage.getItem('pageCount')) > 3 ||
							props.match.path === intl.getHTML('PageRoutes.Step4')
						}
					/>
					<div className='itemContainer'>
						{parseInt(sessionStorage.getItem('pageCount')) > 3 ? (
							<CompleteCheckMark>
								<svg className='checkmark ' viewBox='0 0 52 52'>
									<circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
									<path fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
								</svg>
							</CompleteCheckMark>
						) : (
							<InCompleteCheckMark active={props.match.path === intl.get('PageRoutes.Step4')}>
								4
							</InCompleteCheckMark>
						)}
						<div className='headerText'>{intl.getHTML('MainHeader.Step4')}</div>
					</div>
				</PanoSection>
			</PanoContainer>
		</PanoFunnelSteps>
	</>
));
