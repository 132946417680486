import React from 'react';
import withChange from '../../hocs/withChange';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import StyledErrorMessage from '../formikErrorMessage';
import { useField, useFormikContext } from 'formik';

const FormikSelect = props => {
	const { setFieldValue, setFieldTouched } = useFormikContext();
	const { changeHandler, options, displayField, valueField, placeholder, ...rest } = props;
	const [field, meta] = useField(props);
	const disabled = props.disabled;

	const getOptions = () =>
		options.map(option => (
			<option key={option[displayField]} value={option[valueField]}>
				{option[displayField]}
			</option>
		));

	function handleOptionChange(selection) {
		setFieldValue(props.name, selection.target.value);
		changeHandler(selection);
	}

	function updateBlur() {
		setFieldTouched(props.name, true);
	}
	return (
		<>
			<select onChange={handleOptionChange} onBlur={updateBlur} {...rest}>
				{placeholder && (
					<option value='' disabled selected>
						{placeholder}{' '}
					</option>
				)}
				{getOptions()}
			</select>
			{meta.touched && meta.error && !disabled ? (
				<StyledErrorMessage>{meta.error}</StyledErrorMessage>
			) : null}
		</>
	);
};
export default WithConnect(withChange(WithCorrelationId(FormikSelect)));
