import styled from 'styled-components';
import { device } from '../../styles/mediaquery';
const Container = styled.div`
	display: flex;
	margin-top: 30px;
`;
const FlexRow = styled.div`
	display: block;
	label {
		font-size: 1rem;
	}
	button {
		font-size: 18px;

		vertical-align: bottom;
		line-height: 20px;
		height: 40px;
		border-radius: 4px;
		padding: 0 20px;
		@media ${device.mobile} {
			width: 100%;
		}
	}
`;
export { Container, FlexRow };
