import styled from 'styled-components';
import { StyledInput } from '../StyledComponents';
const Container = styled.div`
	margin-top: 20px;
	button {
		margin-left: 10px;
	}
`;
const FlexRow = styled.div`
	display: flex;
	margin-top: 5px;
	input {
		flex: 2 0 150px;
	}
`;
const AreaCodeInput = styled(StyledInput)``;
export { Container, FlexRow, AreaCodeInput };
