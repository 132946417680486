import React from 'react';
import PropTypes from 'prop-types';
import withChange from '../../hocs/withChange';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';

const SimpleSelect = props => {
	const { changeHandler, options, displayField, valueField, placeholder, ...rest } = props;
	const getOptions = () =>
		options.map(option => (
			<option
				key={option[displayField] !== undefined ? option[displayField] : option}
				value={option[valueField] !== undefined ? option[valueField] : option}
			>
				{option[displayField] !== undefined ? option[displayField] : option}
			</option>
		));

	return (
		<select onChange={changeHandler} {...rest}>
			{placeholder && (
				<option value='' disabled selected>
					{placeholder}{' '}
				</option>
			)}
			{getOptions()}
		</select>
	);
};

SimpleSelect.propTypes = {
	options: PropTypes.array.isRequired,
	displayField: PropTypes.string.isRequired,
	valueField: PropTypes.string.isRequired,
	changeHandler: PropTypes.func.isRequired
};

export default WithConnect(withChange(WithCorrelationId(SimpleSelect)));
