import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
   *, html, body{
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;

  }

  html { 
    overflow-y: scroll;
    overflow-x: hidden;   
  }

  body {
    font-size: 14px;
    color: #231f20;
    background: #fff;
    button{
      outline:none !important;
    }
  }
  input[type="text"],input[type="tel"], input[type="email"], input[type="password"]{
    box-shadow:0 0.0625rem 0.125rem rgb(0 0 0 / 15%) !important;
  }
  ul{
    padding:0;
  }
  a,
  a:hover,
  a:focus {
    text-decoration: none;
    color: #0086ca;
  }

  a {
    color: inherit;
  }


  /* ----------------------------
  Typography
  ------------------------------- */
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  h1,
  .h1 {
    font-size: 24px;
  }

  h2,
  .h2 {
    font-size: 18px;
  }

  h3,
  .h3 {
    font-size: 16px;
  }

  h4,
  .h4 {
  font-size: 13px;
  }

  h5,
  .h5 {
    font-size: 12px;
  }

  p {
    font-size: 14px;
  }

  .currentVersion{
    font-size: 12px;
    font-weight:bold;
    background: aliceblue;
    text-align: center;
    div{
      font-size:11px;
      font-weight:normal;
    }
    position:fixed;
    width:200px;
  }

  .footer-success{
		text-align: center;
    color: #fff;
		margin: 50px auto 0;
    padding: 30px 0;
		background: #000;
    -webkit-font-smoothing: auto;
		sup {
			line-height: 16px;
			margin-right: 5px;
		}
	}
  .footer-standard{
    text-align: center;
    margin: 50px auto 0;
    padding: 10px;
  }
`;

export default GlobalStyles;
