import React, { useContext, useEffect, useState } from 'react';
import { isMobile, isDesktop, isChrome, isSafari, isFirefox } from 'react-device-detect';
import { imageHeaderMapping, determineContactInfo } from 'util/Common';
import efaxjp_support from 'static/images/efaxjp_support.png';
import { HeaderContainer, HeaderLeft, HeaderRight, Anchor, HeaderRightImage } from './HeaderStyle';
import { StyledImage } from 'components/StyledComponents';
import intl from 'react-intl-universal';
import { Parser } from 'html-to-react';
import { getOfferCodeFromSession } from 'util/PlanConfiguration';
import { RefreshLogoContext } from '../../context/refreshLogoContext';
const Header = () => {
	const { refreshLogo, offerCode } = useContext(RefreshLogoContext);
	const [render, setRender] = useState(null);
	let renderHtml;

	const getContactData = offerCodeDetails => {
		if (!offerCodeDetails) {
			const contactInfo = [determineContactInfo()];
			offerCodeDetails = contactInfo.map(({ Title, Href }) => ({ title: Title, href: Href }));
		}
		if (offerCodeDetails) {
			const contactInfo = offerCodeDetails.contactInfo
				? offerCodeDetails.contactInfo
				: offerCodeDetails[0];

			if (isMobile && isDesktop && (isChrome || isSafari || isFirefox)) {
				renderHtml = (
					<div>
						<a href={contactInfo.href}> {Parser().parse(contactInfo.title)}</a>
					</div>
				);
			} else if (isDesktop) {
				renderHtml = <div>{Parser().parse(contactInfo.title)}</div>;
			} else {
				renderHtml = (
					<div>
						<a href={contactInfo.href}>{Parser().parse(contactInfo.title)}</a>
					</div>
				);
			}
			setRender(renderHtml);
		}
	};

	const renderImageFormat = () => {
		if (intl.get('SystemConfig.DisplayContactImageInsteadOfText') && !isMobile) {
			return (
				<HeaderRightImage>
					<StyledImage src={imageMappingonSide()} />{' '}
				</HeaderRightImage>
			);
		}
	};

	useEffect(() => {
		const offerCodeDetails = getOfferCodeFromSession();
		if (!offerCodeDetails) {
			setTimeout(() => {
				getContactData(offerCodeDetails);
			}, 1600);
		} else {
			getContactData(offerCodeDetails);
		}
	}, [refreshLogo]);

	const imageMappingonSide = () => {
		switch (intl.get('SystemConfig.CountryCode').toLowerCase()) {
			case 'jp':
				return efaxjp_support;
		}
	};

	return (
		<HeaderContainer>
			<HeaderLeft>
				<Anchor>
					<StyledImage
						src={imageHeaderMapping(offerCode)}
						alt={intl.get('MainHeader.LogoAltText')}
					/>
				</Anchor>
			</HeaderLeft>
			<HeaderRight>
				{intl.get('SystemConfig.DisplayContactImageInsteadOfText') ? renderImageFormat() : render}
			</HeaderRight>
		</HeaderContainer>
	);
};

export default Header;
