import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import WithClick from '../../hocs/withclick';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';

//import * as classes from './Radio.module.css';

const Radio = props => {
	const { clickHandler, text, img, ...rest } = props;
	return (
		<label>
			<input
				{...rest}
				onClick={event => {
					clickHandler(event);
				}}
				type='radio'
			/>
			{text ? <span>{text}</span> : null}
			{img ? <img src={img} /> : null}
		</label>
	);
};

Radio.propTypes = {
	clickHandler: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired
};

export default WithConnect(WithCorrelationId(WithClick(Radio)));
