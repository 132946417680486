import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledH3 } from 'components/StyledComponents';
import { Container, FlexRow } from './TollFreeSelectorStyle';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import intl from 'react-intl-universal';
import { SearchableSelect } from 'lib/funnel-auditor';

const TollFreeSelector = ({
	handleTollFreeAreaCodeSelect,
	handleDisabledControl,
	disabledControl,
	selectedCountryCode
}) => {
	let location = useLocation();
	const [selectedValue, setSelectedValue] = useState('');
	const [tollfreeAreaCodes, setTollFreeAreaCodes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);

	const onSelection = value => {
		const { pathname, search } = location;
		setSelectedValue(value);
		handleTollFreeAreaCodeSelect({ tollFreeAreaCode: value, payload: { pathname, search } });
	};

	useEffect(() => {
		const getTollFreeAreaCodes = async () => {
			handleDisabledControl(true);
			try {
				const response = intl.get('ChooseNumber.TollFreeNumbersByCountry')[selectedCountryCode];
				setTollFreeAreaCodes(response);
				setFailed(false);
				setLoading(false);
				handleDisabledControl(false);
			} catch (error) {
				setFailed(true);
				setLoading(false);
				handleDisabledControl(false);
			}
		};
		getTollFreeAreaCodes();
	}, []);

	return (
		<Container>
			<StyledH3 hideOnSmall>{intl.get('ChooseNumber.TollFreeSearch.Header')}</StyledH3>
			{loading ? (
				<LoadingSpinner />
			) : (
				<FlexRow>
					<SearchableSelect
						name={'tollfreeDropdown'}
						placeholder={intl.get('ChooseNumber.TollFreeSearch.DropdownTitle')}
						value={selectedValue}
						handler={onSelection}
						options={tollfreeAreaCodes}
						disabled={disabledControl}
					/>
				</FlexRow>
			)}
		</Container>
	);
};

export default TollFreeSelector;
