import React from 'react';
import { connect } from 'react-redux';
import * as TrackerActions from '../../store/tracker/actions';
import * as uiActions from '../../store/ui/actions';

const mapDispatchToProps = {
	addEvents: TrackerActions.addEvents,
	postEvents: TrackerActions.postEvents,
	resetEvents: TrackerActions.resetEvents,
	resetServerError: uiActions.setServerError
};
export default WrappedComponent =>
	connect(null, mapDispatchToProps)(props => <WrappedComponent {...props} />);
