import styled from 'styled-components';
import { device } from '../../styles/mediaquery';
const PhoneListWrapper = styled.ul`
	margin-right: 0;
	max-width: 600px;
	li.itemlist {
		@media ${device.mobile} {
			width: 100%;
		}
		width: 33.3%;
	}
`;

const PhoneNumberWrapper = styled.li`
	display: inline-block;
	border: 1px solid #f1f1f1;
	padding: 10px 10px 0 10px;
	background: #fafafa;
	white-space: nowrap;
	:only-child {
		width: 100%;
	}
	span,
	label {
		cursor: pointer;
		padding-left: 10px;
	}
	@media ${device.mobile} {
		width: 100%;
	}
	background-image: none;
	input {
		background-image: none;
	}
`;

export { PhoneListWrapper, PhoneNumberWrapper };
