import styled from 'styled-components';

const StyledErrorMessage = styled.div`
	font-size: 14px;
	color: #990000;
	font-weight: bold;
	margin: 5px 0px 0;
	line-height: 100%;
	width: 100%;
	div {
		padding-bottom: 5px;
	}
`;

export default StyledErrorMessage;
