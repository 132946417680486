import React from 'react';
import PropTypes from 'prop-types';
import withChange from '../../hocs/withChange';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import SelectSearch from 'react-select-search';
import Fuse from 'fuse.js';
import './select-search.css';
import intl from 'react-intl-universal';

const SearchableSelect = props => {
	let { name, changeHandler, options, placeholder, searchable = true, ...rest } = props;

	const handleOptionChange = selection => {
		changeHandler(selection);
	};

	return (
		<SelectSearch
			name={name}
			id={name}
			options={options}
			onChange={handleOptionChange}
			search={options && options.length > 1 ? true : false}
			emptyMessage={intl.get('ValidationMessages.SearchNotFound')}
			required
			{...rest}
			filterOptions={list => {
				try {
					const fuse = new Fuse(list, {
						keys: ['name'],
						threshold: 0.3
					});

					return value => {
						if (!value.length) {
							return list;
						}

						return fuse.search(value);
					};
				} catch (e) {
					return list;
				}
			}}
			placeholder={placeholder}
			autoComplete='new'
			printOptions='on-focus'
		/>
	);
};

SearchableSelect.propTypes = {
	options: PropTypes.array.isRequired,
	changeHandler: PropTypes.func.isRequired
};

export default WithConnect(withChange(WithCorrelationId(SearchableSelect)));
