import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './SessionTimeout.css';
import { StyledContinueButton } from 'components/StyledComponents';
import intl from 'react-intl-universal';

const SessionTimeout = ({ showModal }) => {
	const handleClick = () => {
		sessionStorage.removeItem('timeout');
		window.location.href = intl.get('SystemConfig.RedirectPath');
	};
	return (
		<Modal
			show={showModal}
			backdrop='static'
			keyboard={false}
			aria-labelledby='contained-modal-title-vcenter'
			centered
			className='international-fax-rates'
		>
			<div>{intl.get('SessionTimeout.MainContent')}</div>
			<StyledContinueButton
				name={'sessionTimeout'}
				onClick={handleClick}
				text={intl.get('SessionTimeout.ButtonText')}
			></StyledContinueButton>
		</Modal>
	);
};

export default SessionTimeout;
