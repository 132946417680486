import React, { useState, useEffect } from 'react';
import { FlexRow, Container } from './PhoneNumberContainerStyles';
import PhoneNumberList from 'components/ChooseNumber/PhoneNumberList';
import PhoneNumberPagination from 'components/ChooseNumber/PhoneNumberPagination';
import { StyledH3 } from 'components/StyledComponents';
import config from 'systemconfig/index';
import get from 'lodash';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import intl from 'react-intl-universal';
import { determineContactInfo } from 'util/Common';

const PhoneNumberContainer = ({ loading, failed, phoneNumbers, getSelectedPhoneNumber }) => {
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		setCurrentPage(1);
		if (phoneNumbers.length) {
			getSelectedPhoneNumber(phoneNumbers[0].phone_number);
		}
	}, [phoneNumbers]);

	const handleClick = phoneNumber => {
		getSelectedPhoneNumber(phoneNumber);
	};

	const paginate = (pageNumber, pageSize) => {
		return get(phoneNumbers)
			.slice((pageNumber - 1) * pageSize)
			.take(pageSize)
			.value();
	};

	const getPagedData = () => {
		return paginate(currentPage, config.phoneNumbersPerPage);
	};

	const handlePagination = () => {
		const TotalPages = Math.ceil(phoneNumbers.length / config.phoneNumbersPerPage);
		currentPage < TotalPages ? setCurrentPage(prevPage => prevPage + 1) : setCurrentPage(1);
	};

	const TotalPages = Math.ceil(phoneNumbers.length / config.phoneNumbersPerPage);

	return (
		<>
			<Container>
				{loading ? (
					<LoadingSpinner />
				) : failed ? (
					<div>
						{intl.get('ChooseNumber.FailedResults.NoResults', {
							contact: determineContactInfo().Contact
						})}
					</div>
				) : (
					<>
						{!intl.get('SystemConfig.HideNumberSelection') && (
							<FlexRow>
								<StyledH3>{intl.get('ChooseNumber.PhoneNumberContainer.Header')}</StyledH3>
								<PhoneNumberList phoneNumbers={getPagedData()} handleClick={handleClick} />
								{TotalPages > 1 && <PhoneNumberPagination handlePagination={handlePagination} />}
							</FlexRow>
						)}
					</>
				)}
			</Container>
		</>
	);
};

export default PhoneNumberContainer;
