import React from 'react';
import PropTypes from 'prop-types';
import WithConnect from '../../hocs/withconnect';
import WithBlur from '../../hocs/withblur';
import WithCorrelationId from '../../hocs/withCorrelationId';

const Input = props => {
	const { blurHandler, enterHandler, ...restProps } = props;
	return <input onBlur={blurHandler} onKeyUp={enterHandler} type='text' {...restProps} />;
};

Input.propTypes = {
	blurHandler: PropTypes.func.isRequired
};
export default WithConnect(WithBlur(WithCorrelationId(Input)));
