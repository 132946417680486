import styled from 'styled-components';

export const StyledRefreshButton = styled.button`
	cursor: pointer;
	color: #006699;
	font-size: 13px;
	font-weight: bold;
	line-height: 17px;
	vertical-align: middle;
	background: none;
	border: none;
	outline: none;
	&:hover {
		color: #0086ca;
	}
	&:focus {
		outline: none;
	}
`;
