import styled from 'styled-components';

const StyledFooter = styled.div`
	text-align: center;
	margin: 50px auto 0;

	padding: 10px;
	sup {
		line-height: 16px;
		margin-right: 5px;
	}
`;
export { StyledFooter };
