import * as TrackerActions from '../../store/tracker/actions';
import * as uiActions from '../../store/ui/actions';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import { setup } from 'axios-cache-adapter';
import config from '../../../../systemconfig';
import intl from 'react-intl-universal';
import { convertStringToJson } from 'util/QueryParams';

localforage.defineDriver(memoryDriver);

const configure = async overrideDefaultCache => {
	// Create `localforage` instance
	const forageStore = localforage.createInstance({
		// List of drivers used
		driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, memoryDriver._driver],
		// Prefix all storage keys to prevent conflicts
		name: 'consensusDB'
	});

	const maxAge = !overrideDefaultCache
		? config.defaultCacheAge
		: intl.get('SystemConfig.DefaultCacheAgeForPhoneNumbers');

	// Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
	return setup({
		cache: {
			invalidate: async (config, request) => {
				if (request.clearCacheEntry) {
					await config.store.removeItem(config.uuid);
				}
			},
			readOnError: async (error, request) => {
				return (
					error.response &&
					error.response.status >= 400 &&
					error.response &&
					error.response.status < 600
				);
			},
			exclude: { query: false },
			key: req => {
				var searchParams = new URL(req.url);
				const jsonQueryParameter = convertStringToJson(searchParams.search, [
					'session_id',
					'correlation_id',
					'id'
				]);
				//   let serialized = req.params instanceof URLSearchParams ?
				// 	req.params.toString() : JSON.stringify(req.params) || ''
				return req.url + jsonQueryParameter;
			},
			maxAge: maxAge * 60 * 1000,
			ignoreCache: config.ignoreCache,
			debug: false,
			store: forageStore // Pass `localforage` store to `axios-cache-adapter`
		}
	});
};
export default class HttpClient {
	static request = async conf => {
		const { url, method, data, payload, pushAuditlogs, eventData } = conf;
		const { addEvents, postEvents } = TrackerActions;
		const { setServerError } = uiActions;
		const params =
			payload && payload.correlation_id ? { correlation_id: payload.correlation_id } : {};

		try {
			const overrideCache = url.includes('getNumbersBy'); //this is used to set a shorter cache time for results
			return configure(overrideCache).then(async api => {
				//let params = {correlation_id: "6d806f21-763d-4b09-b9e8-e0fc1aaf97c9"};
				//let data = undefined;
				let config = {
					url: url,
					method: method,
					params: params,
					data: data
				};
				const response = await api(config);
				if (pushAuditlogs) {
					setTimeout(() => {
						const store = window['store'];
						store.dispatch(postEvents({ eventData }));
					}, 300);
				}
				return Promise.resolve(response);
				// Display something beautiful with `response.data` ;)
			});
		} catch (error) {
			const urlMinusParams = url.split('?')[0];
			const methodName = urlMinusParams.substring(urlMinusParams.lastIndexOf('/') + 1);
			const store = window['store'];
			const { pathname, search } = payload;
			store.dispatch(setServerError(true));
			store.dispatch(
				addEvents({
					time: new Date().getTime(),
					event: methodName,
					page: pathname,
					queryParams: search,
					correlation_id: payload.correlation_id ? payload.correlation_id : null,
					error,
					value: payload.value || eventData.value,
					controlType: payload.controlType || eventData.controlType,
					controlName: payload.controlName || eventData.controlName
				})
			);
			setTimeout(() => {
				store.dispatch(postEvents());
			}, 1500);
			return Promise.reject(error);
		}
	};
}
