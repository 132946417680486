import React from 'react';
import WithClick from '../../hocs/withclick';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import { useField } from 'formik';
import { StyledContainer, StyledCheckMark } from './formikCheckboxStyles';
import StyledErrorMessage from '../formikErrorMessage';

const FormikCheckBox = props => {
	const { clickHandler, text, mouseDownHandler, ...rest } = props;
	const [field, meta] = useField(props);
	return (
		<>
			{meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}{' '}
			<StyledContainer>
				{text}
				<input {...rest} {...field} onClick={clickHandler} type='checkbox' />
				<StyledCheckMark />
			</StyledContainer>
		</>
	);
};

export default WithConnect(WithClick(WithCorrelationId(FormikCheckBox)));
