import React, { useState, useEffect } from 'react';
import { StyledRadio, StyledH3 } from 'components/StyledComponents';
import { Container, FlexRow } from './SearchBySelectorStyles';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';
import { SearchableSelect } from 'lib/funnel-auditor';

const SearchBy = ({
	searchByOptions,
	handleSelectedSearch,
	loadingStatesForCountry,
	statesLoadedForCountry,
	disabledControl,
	resetComponent
}) => {
	const [selectedValue, setSelectedValue] = useState('');

	useEffect(() => {
		if (resetComponent) {
			setSelectedValue(searchByOptions[0]);
		}
	}, [resetComponent]);

	useEffect(() => {
		setSelectedValue(searchByOptions[0]);
	}, [searchByOptions]);

	const handleClick = event => {
		const value = event && event.target ? event.target.value : event;
		setSelectedValue(value);
		handleSelectedSearch(value);
		window.sessionStorage.setItem('searchBy', value);
	};

	if (!isMobile) {
		if (searchByOptions.length > 1) {
			return (
				<Container>
					<StyledH3>{intl.get('ChooseNumber.SearchBy.Header')}</StyledH3>
					<FlexRow>
						{searchByOptions.map(c => (
							<StyledRadio
								handler={handleClick}
								//defaultChecked={i === 0}
								checked={selectedValue === c}
								name='searchBy'
								value={c}
								text={c}
								key={c}
								disabled={disabledControl || (loadingStatesForCountry && !statesLoadedForCountry)}
							/>
						))}
					</FlexRow>
				</Container>
			);
		} else {
			return '';
		}
	} else {
		if (searchByOptions.length > 1) {
			searchByOptions = searchByOptions.map(e => ({ value: e, name: e }));
			return (
				<Container>
					<StyledH3>{intl.get('ChooseNumber.SearchBy.Header')}</StyledH3>
					<FlexRow>
						<SearchableSelect
							handler={handleClick}
							options={searchByOptions}
							name='searchBy'
							value={selectedValue}
						/>
					</FlexRow>
				</Container>
			);
		} else {
			return '';
		}
	}
};
export default SearchBy;
