import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CustomEvent from '../event';

const withChange = WrappedComponent =>
	withRouter(props => {
		const {
			// props coming from WithConnect Hoc
			addEvents,
			postEvents,
			resetEvents,
			resetServerError,

			// prop coming from WithRouter(provides information about page)
			match,
			location,
			staticContext,

			// props coming from the application( the place where the wrapped component is used )
			handler,

			// some additional props specific to this Hoc component
			maskType,

			...rest
		} = props;

		const changeHandler = event => {
			const target = event.target !== undefined ? event.target : event;
			if (maskType) target.maskType = maskType;
			const customEvent = new CustomEvent({
				target,
				match,
				event,
				location
			});
			addEvents(customEvent);
			if (handler) handler(event);
		};

		return <WrappedComponent {...rest} changeHandler={changeHandler} />;
	});

withChange.propTypes = {
	addEvents: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	handler: PropTypes.func
};

withChange.defaultProps = {
	handler: () => {}
};

export default withChange;
