import { useState } from 'react';
import { RefreshLogoContext } from '../context/refreshLogoContext';
import intl from 'react-intl-universal';

export const RefreshLogoProvider = (props) => {
	const [refreshLogo, setRefreshLogo] = useState(false);
	let offer_code = intl.get('SystemConfig.DefaultOfferCode');
	const { search } = location;
	const queryParameters = new URLSearchParams(search.toLowerCase());
	if (queryParameters.has('offercode')) {
		offer_code = queryParameters.get('offercode');
	}
	const [offerCode, setOfferCode] = useState(offer_code);

	const dispatchHeaderLogoEvent = (actionType, headerLogo, offerCode = undefined) => {
		switch (actionType) {
			case 'UpdateHeaderLogo':
				if (!offerCode) {
					setOfferCode(offerCode);
				}

				setRefreshLogo(headerLogo);
				return;
			default:
				return;
		}
	};
	return (
		<RefreshLogoContext.Provider value={{ refreshLogo, offerCode, dispatchHeaderLogoEvent }}>
			{props.children}
		</RefreshLogoContext.Provider>
	);
};
