import React, { useState, useEffect, lazy, useContext } from 'react';
import Pano from 'components/Common/Pano';
import SearchBySelector from 'components/ChooseNumber/SearchBySelector';
import CountrySelector from 'components/ChooseNumber/CountrySelector';
import StateSelector from 'components/ChooseNumber/StateSelector';
import CitySelector from 'components/ChooseNumber/CitySelector';
import AreaCodeSearch from 'components/ChooseNumber/AreaCodeSearch';
import TollFreeSelector from 'components/ChooseNumber/TollFreeSelector';
import PhoneNumberContainer from 'components/ChooseNumber/PhoneNumberContainer';
import { Container, LeftPanel, RightPanel, StyledH1, ButtonContainer } from './ChooseNumberStyles';
import { services } from 'services/ChooseNumberService';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import StyledErrorMessage from 'lib/funnel-auditor/components/formikErrorMessage';
import {
	setQueryParametersInSessionStorage,
	GAPageEventTracker,
	GACustomerEventTracker,
	addRemoveFooterClass,
} from 'util/Common';
import { getOfferCodeObjectByOfferCode } from 'util/PlanConfiguration';
import { withPageLoad } from '../lib/funnel-auditor';
import intl from 'react-intl-universal';
import { StyledContinueButton } from 'components/StyledComponents';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { getOfferCodeQueryParam } from 'util/QueryParams';

const ChooseNumber = () => {
	const ZipCodeSearch = lazy(() => import('components/ChooseNumber/ZipCodeSearch'));
	const ZipCodeSearchSelector = lazy(() => import('components/ChooseNumber/ZipCodeSearchSelector'));
	const CustomerTestimonials = lazy(() => import('components/ChooseNumber/CustomerTestimonial'));

	const [selectedCountry, setSelectedCountry] = useState(''); //useHistoryState('selectedCountry', '');
	const [loadingStatesForCountry, setLoadingStatesForCountry] = useState(false);
	const [statesLoadedForCountry, setStatesLoadedForCountry] = useState(false);
	const [loadingCitiesForCountry, setLoadingCitiesForCountry] = useState(false);
	const [citiesLoadedForCountry, setCitiesLoadedForCountry] = useState(false);
	// searchBy state
	const [displaySearchBy, setDisplaySearchBy] = useState(false);
	const [searchByOptions, setSearchByOptions] = useState([]);
	const [selectedSearchBy, setSelectedSearchBy] = useState('');
	const [reset, setReset] = useState(false);

	// state/province state
	const [selectedState, setSelectedState] = useState(''); //useHistoryState('selectedState', '');
	const [loadingCitiesForState, setLoadingCitiesForState] = useState(false);
	const [citiesLoadedForState, setCitiesLoadedForState] = useState(false);
	// zip code search cities state
	const [displayZipCodeSearchCities, setDisplayZipCodeSearchCities] = useState(false);
	const [loadingZipCodeSearchCities, setLoadingZipCodeSearchCities] = useState(false);
	const [zipCodeSearchCitiesFailed, setZipCodeSearchCitiesFailed] = useState(false);
	const [zipCodeSearchCities, setZipCodeSearchCities] = useState([]);
	// phone numbers state
	const [displayPhoneNumbers, setDisplayPhoneNumbers] = useState(false);
	const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
	const [phoneNumbersFailed, setPhoneNumbersFailed] = useState(false);
	const [phoneNumbersLoaded, setPhoneNumbersLoaded] = useState(false);
	const [phoneNumbers, setPhoneNumbers] = useState([]);
	const [errorMessage, setErrorMessage] = useState('');
	const [enableSpinner, setEnableSpinner] = useState(false);
	const [buttonName, setButtonName] = useState(intl.get('Buttons.ChooseNumber.DefaultButtonText'));
	const [disabledButton, setDisabledButton] = useState(true);
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');

	const history = useHistory();
	let location = useLocation();
	// disabled for all fields
	const [disabledControl, setDisabledControl] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);

		setQueryParametersInSessionStorage(location);

		const getOfferCodeDetails = async (offerCodeString) => {
			//get the collection and put in storage
			await services
				.getOfferCodeDetails(offerCodeString, {
					pathname: '/',
					search: location.search,
					isCustom: true,
				})
				.then(() => {
					const offerDetails = getOfferCodeObjectByOfferCode(offerCodeString);
					if (offerDetails) {
						window.sessionStorage.setItem('offercode', JSON.stringify(offerDetails));
						if (
							offerDetails.offer_code !== null &&
							(offerDetails.offer_code.includes('DTRI') ||
								offerDetails.pricing.free_trial_duration > 0)
						) {
							setButtonName(intl.get('Buttons.ChooseNumber.StartFreeTrialButtonText'));
						}
					}
				});
		};

		let offerCode =
			getOfferCodeQueryParam().length > 0
				? getOfferCodeQueryParam()
				: intl.get('SystemConfig.DefaultOfferCode');

		getOfferCodeDetails(offerCode);
		sessionStorage.removeItem('reservedNumber');
		sessionStorage.removeItem('pcity');
		sessionStorage.removeItem('orderNumber');
		sessionStorage.removeItem('accountForm');
		sessionStorage.removeItem('timeout');
		sessionStorage.setItem('pageCount', '1');

		GAPageEventTracker();
		GACustomerEventTracker();
		addRemoveFooterClass(false);
	}, []);

	const resetState = () => {
		setLoadingStatesForCountry(false);
		setStatesLoadedForCountry(false);
		setLoadingCitiesForCountry(false);
		setCitiesLoadedForCountry(false);
		setSelectedState('');
		setLoadingCitiesForState(false);
		setCitiesLoadedForState(false);
		setDisabledButton(true);
		setDisplayZipCodeSearchCities(false);
		setLoadingZipCodeSearchCities(false);
		setZipCodeSearchCitiesFailed(false);
		setZipCodeSearchCities([]);

		setDisplayPhoneNumbers(false);
		setLoadingPhoneNumbers(false);
		setPhoneNumbersFailed(false);
		setPhoneNumbersLoaded(false);
		setPhoneNumbers([]);
	};

	const handleCountrySelect = ({ countryCode, searchByOptions }) => {
		resetState();
		setReset(true);
		setSelectedCountry(countryCode);
		setDisabledControl(true);
		setSelectedSearchBy(searchByOptions[0]);
		setDisplaySearchBy(true);
		setSearchByOptions(searchByOptions);

		if (searchByOptions[0] === 'City') setLoadingCitiesForCountry(true);
		if (searchByOptions[0] === 'State') setLoadingStatesForCountry(true);
		setDisabledControl(false);
	};

	const handleSearchBySelect = (selectedSearchBy) => {
		resetState();
		setDisabledControl(true);
		setDisabledButton(true);
		setReset(false);
		if (selectedSearchBy === intl.get('ChooseNumber.SearchBy.UseExistingNumber')) {
			window.location.href = intl.get('ChooseNumber.SearchBy.PortingURL');
		}

		setSelectedSearchBy(selectedSearchBy);
		setDisabledControl(false);
	};

	const handleAreaCodeSearch = async ({ areaCode, payload }) => {
		setLoadingPhoneNumbers(true);
		setDisplayPhoneNumbers(true);
		setDisabledControl(true);
		setErrorMessage('');
		setDisabledButton(true);

		try {
			await services.getNumbersByAreaCode(selectedCountry, areaCode, payload).then((response) => {
				setPhoneNumbers(response.data.phone_numbers);
				setLoadingPhoneNumbers(false);
				setPhoneNumbersFailed(false);
				setDisabledControl(false);
				setDisabledButton(false);
			});
		} catch (error) {
			setLoadingPhoneNumbers(false);
			setPhoneNumbersFailed(true);
			setDisabledControl(false);
		}
	};

	const handleZipCodeSearch = async ({ zipCode, payload }) => {
		setDisplayZipCodeSearchCities(true);
		setLoadingZipCodeSearchCities(true);
		setLoadingPhoneNumbers(false);
		setDisplayPhoneNumbers(false);
		setDisabledControl(true);
		setErrorMessage('');

		try {
			await services
				.getCitiesByCountryAndPostalCode(selectedCountry, zipCode, payload)
				.then((response) => {
					setZipCodeSearchCities(response.data);
					setLoadingZipCodeSearchCities(false);
					setZipCodeSearchCitiesFailed(false);
					setDisabledControl(false);
				});
		} catch (error) {
			setLoadingZipCodeSearchCities(false);
			setZipCodeSearchCitiesFailed(true);
			setDisabledControl(false);
		}
	};

	const handleStateSelect = ({ stateCode }) => {
		resetState();
		setLoadingCitiesForState(true);
		setSelectedState(stateCode);
	};

	const getNumbersByCity = async (cityCode, payload) => {
		setPhoneNumbers([]);
		setLoadingPhoneNumbers(true);
		setDisplayPhoneNumbers(true);
		setPhoneNumbersLoaded(false);
		setDisabledControl(true);
		setDisabledButton(true);

		sessionStorage.setItem('pcity', cityCode);

		try {
			await services.getNumbersByCityInternational(cityCode, payload).then((resp) => {
				setLoadingPhoneNumbers(false);
				setPhoneNumbers(resp.data.phone_numbers);
				setPhoneNumbersLoaded(true);
				setPhoneNumbersFailed(false);
				setDisabledControl(false);
				setDisabledButton(false);
			});
		} catch (error) {
			sessionStorage.removeItem('pcity');
			setPhoneNumbersFailed(true);
			setLoadingPhoneNumbers(false);
			setPhoneNumbersLoaded(true);
			setDisabledControl(false);
		}
	};

	const handleCitySelect = async ({ cityCode, payload }) => {
		getNumbersByCity(cityCode, payload);
	};

	const handleTollFreeAreaCodeSelect = async ({ tollFreeAreaCode, payload }) => {
		getNumbersByCity(tollFreeAreaCode, payload);
	};

	const handleZipCodeCitySelect = ({ cityCode, payload }) => {
		getNumbersByCity(cityCode, payload);
	};

	const onCitiesLoadedForState = () => {
		setCitiesLoadedForState(true);
	};

	const onCitiesLoadedForCountry = () => {
		setCitiesLoadedForCountry(true);
	};

	const onStatesLoadedForCountry = () => {
		setStatesLoadedForCountry(true);
	};

	const handlerError = (msg) => {
		setErrorMessage(msg);
	};
	const getSelectedPhoneNumber = (phoneNumber) => {
		setSelectedPhoneNumber(phoneNumber);
		handlerError('');
	};

	const disableContinueButton = (activate) => {
		setDisabledButton(activate);
		setDisplayPhoneNumbers(false);
	};

	const handleReserveNumber = async () => {
		setDisabledButton(true);
		setEnableSpinner(true);
		sessionStorage.removeItem('reservedNumber');
		const { pathname, search } = location;
		handlerError('');

		try {
			await services
				.reserveSelectedNumber(selectedPhoneNumber, { pathname, search })
				.then((response) => {
					let redirectPage = intl.get('PageRoutes.Step2');
					if (response.status === 200) {
						sessionStorage.setItem('reservedNumber', selectedPhoneNumber);
						const { search } = location;
						history.push({
							pathname: redirectPage,
							search,
						});
					} else {
						handlerError(intl.get('ChooseNumber.FailedResults.ReserveNumberError'));
						setDisabledButton(false);
						setEnableSpinner(false);
					}
				});
		} catch (error) {
			handlerError(intl.get('ChooseNumber.FailedResults.ReserveNumberError'));
			setDisabledButton(false);
			setEnableSpinner(false);
		}
	};

	return (
		<>
			<Pano />
			<Container>
				<LeftPanel>
					<StyledH1>{intl.get('ChooseNumber.Header')}</StyledH1>
					<CountrySelector
						loadingCitiesForCountry={loadingCitiesForCountry}
						citiesLoadedForCountry={citiesLoadedForCountry}
						loadingStatesForCountry={loadingStatesForCountry}
						statesLoadedForCountry={statesLoadedForCountry}
						onCountrySelect={handleCountrySelect}
						disabledControl={disabledControl}
					/>
					{displaySearchBy && (
						<SearchBySelector
							searchByOptions={searchByOptions}
							handleSelectedSearch={handleSearchBySelect}
							loadingStatesForCountry={loadingStatesForCountry}
							statesLoadedForCountry={statesLoadedForCountry}
							disabledControl={disabledControl}
							resetComponent={reset}
						/>
					)}

					{selectedSearchBy === intl.get('ChooseNumber.SearchBy.AreaCode') &&
						!intl.get('SystemConfig.HideAreaCode') && (
							<AreaCodeSearch
								searchHandler={handleAreaCodeSearch}
								loadingPhoneNumbers={loadingPhoneNumbers}
								disableContinueButton={disableContinueButton}
							/>
						)}

					{(selectedSearchBy === 'Province' || selectedSearchBy === 'State') && (
						<StateSelector
							handleStateSelect={handleStateSelect}
							selectedCountry={selectedCountry}
							selectedSearchBy={selectedSearchBy}
							loadingCities={loadingCitiesForState}
							citiesLoaded={citiesLoadedForState}
							onStatesLoadedForCountry={onStatesLoadedForCountry}
							disabledControl={disabledControl}
						/>
					)}

					{(selectedSearchBy === 'City' || selectedState !== '') && (
						<CitySelector
							handleCitySelect={handleCitySelect}
							selectedCountry={selectedCountry}
							selectedState={selectedState}
							selectedSearchBy={selectedSearchBy}
							onCitiesLoadedForState={onCitiesLoadedForState}
							onCitiesLoadedForCountry={onCitiesLoadedForCountry}
							loadingPhoneNumbers={loadingPhoneNumbers}
							phoneNumbersLoaded={phoneNumbersLoaded}
							disabledControl={disabledControl}
							handleDisabledControl={setDisabledControl}
						/>
					)}

					{selectedSearchBy === intl.get('ChooseNumber.SearchBy.ZipCode') &&
						intl.get('ChooseNumber.SearchBy.ZipCode') && (
							<ZipCodeSearch
								searchHandler={handleZipCodeSearch}
								loadingZipCodeSearchCities={loadingZipCodeSearchCities}
								disabledControl={disabledControl}
							/>
						)}

					{displayZipCodeSearchCities && (
						<ZipCodeSearchSelector
							handler={handleZipCodeCitySelect}
							citiesForZipCode={zipCodeSearchCities}
							loading={loadingZipCodeSearchCities}
							failed={zipCodeSearchCitiesFailed}
							loadingPhoneNumbers={loadingPhoneNumbers}
							phoneNumbersLoaded={phoneNumbersLoaded}
						/>
					)}
					{[
						intl.get('ChooseNumber.SearchBy.TollFree'),
						intl.get('ChooseNumber.SearchBy.NationalNumbers'),
					].includes(selectedSearchBy) && (
						<TollFreeSelector
							handleTollFreeAreaCodeSelect={handleTollFreeAreaCodeSelect}
							handleDisabledControl={setDisabledControl}
							disabledControl={disabledControl}
							selectedCountryCode={selectedCountry}
						/>
					)}
					{displayPhoneNumbers && (
						<PhoneNumberContainer
							loading={loadingPhoneNumbers}
							failed={phoneNumbersFailed}
							phoneNumbers={phoneNumbers}
							getSelectedPhoneNumber={getSelectedPhoneNumber}
						/>
					)}
					<ButtonContainer>
						<StyledContinueButton
							name={'numberContinue'}
							disabled={disabledButton}
							mouseDownHandler={handleReserveNumber}
							text={buttonName}
						></StyledContinueButton>
						{enableSpinner ? <LoadingSpinner /> : ''}
					</ButtonContainer>

					{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
				</LeftPanel>
				<RightPanel>
					{intl.getHTML('PanelSections.PanelA.Content') && <CustomerTestimonials />}
				</RightPanel>
			</Container>
		</>
	);
};

export default withRouter(withPageLoad({ WrappedComponent: ChooseNumber }));
