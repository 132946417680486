import styled from 'styled-components';
import { device } from 'styles/mediaquery';
import intl from 'react-intl-universal';

const Container = styled.div`
	margin: auto;
	display: block;
	max-width: 550px;
	padding-top: 40px;
	@media ${device.mobile} {
		padding-top: 0px;
		margin: 0 10px;
		width: auto;
	}
`;
const LeftPanel = styled.div`
	div select {
		max-width: 530px;
	}
`;

const StyledH1 = styled.h1`
	font-size: 35px;
	white-space: nowrap;
	@media ${device.mobile} {
		font-size: 30px;
	}
	color: #666;
	letter-spacing: -1px;
	line-height: 1.2;
`;
const RightPanel = styled.div`
	flex: 1;
	flex-direction: column;
	display: none;
	margin-right: 20px;
	@media ${device.tablet} {
		align-self: flex-end;
		margin-top: 30px;
		max-width: 260px;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 30px;
	button {
		font-size: 18px;
		vertical-align: bottom;
		line-height: 20px;
		height: 40px;
		padding: 0 20px;
		border: ${intl.get('Buttons.Color.Border')};
		border-radius: ${intl.get('Buttons.Color.BorderRadius')};
		@media ${device.mobile} {
			width: 100%;
		}
	}
`;

export { Container, LeftPanel, RightPanel, StyledH1, ButtonContainer };
