import Cookies from 'js-cookie';

let cookieElements = Cookies.get('GEOINFO') !== undefined ? Cookies.get('GEOINFO').split('&') : '';
const userCountry = () => {
	return cookieElements && cookieElements.length > 0 && cookieElements[4].replace('country=', '');
};

const userIpAddress = () => {
	return cookieElements && cookieElements.length > 0 && cookieElements[0].replace('ip=', '');
};

const userState = () => {
	return cookieElements && cookieElements.length > 0 && cookieElements[3].replace('state=', '');
};

const marketingCookies = () => {
	let cookies = {};
	if (document.cookie && document.cookie !== '') {
		var split = document.cookie.split(';');
		for (var i = 0; i < split.length; i++) {
			var name_value = split[i].split('=');
			name_value[0] = name_value[0].replace(/^ /, '');
			if (
				name_value[0].indexOf('_ga') === 0 ||
				name_value[0].indexOf('_vwo_uuid_v2') === 0 ||
				name_value[0].indexOf('_vis_opt_exp') === 0
			) {
				cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1]);
			}
		}
	}

	return cookies;
};

const fullGeoInfo = () => {
	try {
		return {
			ipAddress:
				cookieElements !== undefined && cookieElements[0] !== undefined
					? cookieElements[0].replace('ip=', '')
					: '',
			areaCode:
				cookieElements !== undefined && cookieElements[1] !== undefined
					? cookieElements[1].replace('areacode=', '')
					: '',
			city:
				cookieElements !== undefined && cookieElements[2] !== undefined
					? cookieElements[2].replace('city=', '')
					: '',
			state:
				cookieElements !== undefined && cookieElements[3] !== undefined
					? cookieElements[3].replace('state=', '')
					: '',
			country:
				cookieElements !== undefined && cookieElements[4] !== undefined
					? cookieElements[4].replace('country=', '')
					: '',
			timezone:
				cookieElements !== undefined && cookieElements[5] !== undefined
					? cookieElements[5].replace('timezone=', '')
					: '',
			bandwidth:
				cookieElements !== undefined && cookieElements[6] !== undefined
					? cookieElements[6].replace('bandwidth=', '')
					: '0',
			cdn:
				cookieElements !== undefined && cookieElements[7] !== undefined
					? cookieElements[7].replace('co=', '')
					: ''
		};
	} catch (error) {
		return {};
	}
};

const GACookieCheck = () => {
	return Cookies.get('_gcl_dc') === undefined ? false : true;
};

export { userCountry, userState, userIpAddress, marketingCookies, fullGeoInfo, GACookieCheck };
