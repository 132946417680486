import { useState, useEffect, useRef } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { v4 as uuidv4 } from 'uuid';
import intl from 'react-intl-universal';
import { parseDomain, ParseResultType } from 'parse-domain';
import efax_logo from 'static/images/logos/efzx_logo.png';
import myfax_logo from 'static/images/logos/myfax_logo.png';
import metrofax_logo from 'static/images/logos/metrofax_logo.png';
import jfax_logo from 'static/images/logos/jfax_logo.png';
import efax_protect_logo from 'static/images/logos/logo_eFax-protect.png';
import SimpleCrypto from 'simple-crypto-js';
import { userCountry, userIpAddress } from 'util/Cookie';
import { convertStringToJson } from 'util/QueryParams';
import NumberFormat from 'react-number-format';
import { isMobile, isDesktop, isChrome, isSafari, isFirefox } from 'react-device-detect';

const getHostNameData = () => {
	const hostName = isLocalHost()
		? intl.get('SystemConfig.HostName')
		: window && window.location && window.location.hostname;

	const parseResult = parseDomain(hostName);

	if (parseResult.type === ParseResultType.Listed) {
		const { subDomains, domain, topLevelDomains } = parseResult;

		return {
			subDomain: subDomains,
			domain: domain,
			subfolders: window.location.pathname.split('/').filter((a) => a),
			topLevelDomain: topLevelDomains,
			fullDomainName: subDomains
				.concat(domain)
				.join('.')
				.concat('.')
				.concat(topLevelDomains.join('.')), //domain.concat('.').concat(topLevelDomains.join('.'))
		};
	} else {
		return {
			subDomain: [],
			subfolders: window.location.pathname.split('/').filter((a) => a),
			domain: window.location.host,
			topLevelDomain: [],
			fullDomainName: window.location.host,
		};
	}
};
const determineApiUrlEndpoint = () => {
	const hostName = getHostNameData().subDomain;
	let url = intl.get('SystemConfig.Endpoints.QA.APIGW');
	if (hostName.includes('secure')) {
		url = intl.get('SystemConfig.Endpoints.Production.APIGW');
	}
	return url;
};

const determineSentryEndpoint = () => {
	const hostName = getHostNameData().subDomain;
	let url = intl.get('SystemConfig.Endpoints.QA.Sentry');
	if (hostName.includes('secure')) {
		url = intl.get('SystemConfig.Endpoints.Production.Sentry');
	}
	return url;
};

const getGTMID = () => {
	return intl.get('SystemConfig.GTMID');
};

const phoneNumberParser = (phoneNumberString) => {
	try {
		if (phoneNumberString === '' || phoneNumberString === undefined) return '';
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const ph = parsePhoneNumberFromString('+' + cleaned);
		return phoneNumberFormatLookup(ph);
	} catch (error) {
		return phoneNumberString;
	}
};

const phoneNumberFormatLookup = (phoneData) => {
	const preferredCitiesList = Object.assign([], intl.get('PreferredCities'));
	const manualCityList = Object.assign([], intl.get('ManualCityList'));
	const pcityList = preferredCitiesList.concat(manualCityList);

	switch (phoneData.country) {
		case 'US': {
			return '1 ' + phoneData.formatNational();
		}
		case 'JP': {
			const result = pcityList.find((e) => e.value == window.sessionStorage.getItem('pcity'));
			if (result && result.format) {
				return (
					<NumberFormat
						displayType={'text'}
						value={phoneData.nationalNumber}
						format={result.format}
					/>
				);
			} else {
				return phoneData.formatInternational();
			}
		}
		default: {
			return phoneData.formatInternational();
		}
	}
};

const Wrapper = (Component, parser) => {
	return function () {
		return <Component {...parser.apply(null, arguments)} />;
	};
};

const paymentTypesByCountries = (countryCode) => {
	switch (countryCode) {
		case 'GB':
		case 'AU':
		case 'BE': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.DirectDebit') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes3'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection3'),
			};
		}
		case 'AT':
		case 'NL': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.ElectronicDebit') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes1'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection1'),
			};
		}
		case 'FR': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.DirectDebit') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes4'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection4'),
			};
		}
		case 'DE': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.ElectronicDebit') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes3'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection3'),
			};
		}
		case 'IE': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes3'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection3'),
			};
		}
		case 'IT':
		case 'PL':
		case 'ES':
		case 'CH': {
			return {
				paymentTypes: [
					{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
					{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
				],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes1'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection1'),
			};
		}
		case 'RO':
		case 'RS': {
			return {
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes3'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection3'),
			};
		}
		case 'CA': {
			return {
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes2'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection2'),
			};
		}
		case 'JP': {
			return {
				paymentTypes:
					intl.get('PaymentTypesOptions.BankTransfer').length > 0
						? [
								{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') },
								{ id: 'BT', name: intl.get('PaymentTypesOptions.BankTransfer') },
						  ]
						: [{ id: 'CC', name: intl.get('PaymentTypesOptions.CreditDebitCard') }],
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes5'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection5'),
			};
		}
		default: {
			return {
				cardTypes: intl.get('PaymentTypesOptions.CreditCardTypes.CardTypes1'),
				approvedCreditCards: intl.get('PaymentTypesOptions.ApprovedCreditCards.CardCollection1'),
			};
		}
	}
};

const cardTypeFix = (cardName) => {
	switch (cardName) {
		case 'amex': {
			return cardName.toUpperCase();
		}
		case 'visa':
		case 'Visa': {
			return cardName.toUpperCase();
		}
		case 'mc':
		case 'MC':
		case 'MasterCard':
		case 'mastercard': {
			return 'MC';
		}
		case 'discover':
		case 'DISCOVER': {
			return 'DISC';
		}
		default:
			return '';
	}
};

const getUserId = () => {
	if (!window.localStorage.getItem('userId')) {
		window.localStorage.setItem('userId', uuidv4());
		return window.localStorage.getItem('userId');
	} else {
		return window.localStorage.getItem('userId');
	}
};

const generateNewSessionId = () => {
	if (!window.sessionStorage.getItem('clientSessionId')) {
		const clientSessionId = uuidv4();
		window.sessionStorage.setItem('clientSessionId', clientSessionId);
		return window.sessionStorage.getItem('clientSessionId');
	} else {
		return window.sessionStorage.getItem('clientSessionId');
	}
};
const getClientSessionId = () => {
	return window.sessionStorage.getItem('clientSessionId');
};
const removeClientSessionId = () => {
	window.sessionStorage.removeItem('clientSessionId');
};
const convertTextToTitleCase = (text) => {
	let camelCaseText = text
		.split(' ')
		.map(function (word, index) {
			// First character upper case else lower case
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		})
		.join(' ');
	return camelCaseText;
};

const setQueryParametersInSessionStorage = (location) => {
	const { search } = location;

	const queryParameters = new URLSearchParams(search.toLowerCase());
	if (queryParameters.has('offercode')) {
		queryParameters.delete('offercode');
	}
	const jsonQueryParameter = convertStringToJson(queryParameters);
	if (isJsonValid(jsonQueryParameter)) {
		Object.keys(JSON.parse(jsonQueryParameter)).map((item, index) => {
			let key = item;
			let keyValue = JSON.parse(jsonQueryParameter)[item];
			if (key === 'vid' || key === 'VID') {
				keyValue = keyValue.replace(/\D/g, '');
			}
			if (key && keyValue) {
				window.sessionStorage.setItem(key, keyValue);
			}
		});
	}
};

const isJsonValid = (json) => {
	try {
		JSON.parse(json);
	} catch {
		return false;
	}
	return true;
};

const pageValidation = (variableList) => {
	const list = variableList.split(/[\s;,|]+/);
	let listContainer = [];
	for (var i = 0; i < list.length; i++) {
		listContainer.push(window.sessionStorage.getItem(list[i]) !== null ? true : false);
	}
	return listContainer.includes(false) ? false : true;
};

const ProperCase = (str) => {
	return str
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.replace(word[0], word[0].toUpperCase());
		})
		.join(' ');
};

const errorCodeResponse = (errors, postal_code) => {
	var i;
	let results = [];
	for (i = 0; i < errors.length; i++) {
		if (errors[i].field === 'customer.address.postal_code') {
			results.push(
				'<div>' + postal_code.concat(intl.get('ValidationMessages.IsIncorrect')) + '</div>'
			);
		} else if (errors[i].error_code === 'DUPLICATED_EMAIL') {
			results.push('<div>' + intl.get('ValidationMessages.EmailInUse') + '</div>');
		} else if (errors[i].error_code === 'DID_NOT_AVAILABLE') {
			results.push('<div>' + intl.get('ValidationMessages.DidNotAvailable') + '</div>');
		} else if (
			errors[i].field === 'customer.payment_method.account_number' ||
			errors[i].field === 'customer.payment_method.card_verification_code' ||
			errors[i].field === 'customer.payment_method.card_expiration_month' ||
			errors[i].field === 'customer.payment_method.card_expiration_year'
		) {
			results.push('<div>' + intl.get('ValidationMessages.BillingError') + '</div>');
		} else {
			results.push('<div>' + intl.get('ValidationMessages.TechnicalError') + '</div>');
		}
	}
	return removeDuplicates(results, JSON.stringify).sort().join('');
};

const removeDuplicates = (a, key) => {
	return [...new Map(a.map((x) => [key(x), x])).values()];
};

const parseName = require('parse-full-name').parseFullName;
const parsedFullName = (full_name) => {
	if (!full_name) return '';
	const parseInfo = parseName(full_name, 'all', 1, 0, 0);
	try {
		return Object.assign(
			{
				first_name:
					parseInfo.first.length > 0
						? parseInfo.title.concat(' ').concat(' '.concat(parseInfo.first).trim()).trim()
						: parseInfo.first.trim(),
			},
			{
				last_name:
					parseInfo.last.length > 0 && parseInfo.last.length > 1
						? parseInfo.last
								.concat(parseInfo.suffix.length > 0 ? ', '.concat(parseInfo.suffix) : '')
								.trim()
						: parseInfo.last.trim(),
			}
		);
	} catch {
		return Object.assign(
			{ first_name: full_name.split(' ')[0] },
			{ last_name: full_name.split(' ')[1] }
		);
	}
};

const imageHeaderMapping = (offerCode) => {
	switch (intl.get('SystemConfig.Brand').toLowerCase()) {
		case 'efax':
			if (isProtectOfferCode(offerCode)) return efax_protect_logo;
			else return efax_logo;
		case 'myfax':
			return myfax_logo;
		case 'metrofax':
			return metrofax_logo;
		case 'jfax':
			return jfax_logo;
	}
};

const encryptString = (content) => {
	const currentDate = new Date();
	const secretKeyString = `${currentDate.getUTCFullYear()}-j2Funnel`;
	const simpleCrypto = new SimpleCrypto(secretKeyString);
	return simpleCrypto.encrypt(content);
};

const decryptString = (content) => {
	try {
		const currentDate = new Date();
		const secretKeyString = `${currentDate.getUTCFullYear()}-j2Funnel`;
		const simpleCrypto = new SimpleCrypto(secretKeyString);
		return simpleCrypto.decrypt(content);
	} catch {
		return content;
	}
};

const GAPageEventTracker = () => {
	return window.dataLayer.push({
		event: 'pageview',
		page: {
			url: document.location.origin + document.location.pathname + document.location.search,
			title: document.location.pathname.replace('/', ''),
		},
	});
};

const GACustomerEventTracker = (order_number, customer_key, phoneNumber) => {
	return window.dataLayer.push({
		event: 'cheq',
		customer: {
			IP: userIpAddress(),
			hostname: getHostNameData().fullDomainName,
			requestID: sessionStorage.getItem('cheqID'),
			orderNumber: order_number,
			customerKey: customer_key,
			DID:
				sessionStorage.getItem('reservedNumber') !== null
					? sessionStorage.getItem('reservedNumber')
					: phoneNumber,
			email:
				sessionStorage.getItem('accountForm') !== null
					? JSON.parse(sessionStorage.getItem('accountForm')).email
					: '',
		},
	});
};

const isUrlValid = (str) => {
	var pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(str);
};

const isLocalHost = () => {
	if (
		window.location.hostname === 'localhost' ||
		window.location.hostname === '127.0.0.1' ||
		window.location.hostname === ''
	) {
		return true;
	}
	return false;
};

const useStateWithCallback = (initialValue) => {
	const callbackRef = useRef(null);

	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		if (callbackRef.current) {
			callbackRef.current(value);

			callbackRef.current = null;
		}
	}, [value]);

	const setValueWithCallback = (newValue, callback) => {
		callbackRef.current = callback;

		return setValue(newValue);
	};

	return [value, setValueWithCallback];
};

const removeDuplicatesFromResponse = (resp) => {
	//filters out dups
	let unique = [];
	resp.forEach((element) => {
		if (!unique.find((e) => e.value === element.value)) {
			unique.push(element);
		}
	});
	return unique;
};

const determineContactInfo = () => {
	let determineContactInfo = Object.assign([], intl.get('MainHeader.ContactList'));
	if (determineContactInfo.length > 1) {
		// Find matching contact based on country code from geoinfo cookie
		const country = userCountry();
		if (country) {
			const result = determineContactInfo.find((e) => e.CountryCode === country);
			if (result) {
				return result;
			} else {
				return determineContactInfo[0];
			}
		} else {
			return determineContactInfo[0];
		}
	} else {
		//only have one just return
		return determineContactInfo[0];
	}
};

const currencyFormatter = (amount) => {
	return new Intl.NumberFormat(intl.get('SystemConfig.Locale'), {
		style: 'currency',
		currency: intl.get('SystemConfig.Currency'),
		currencyDisplay: 'narrowSymbol',
	}).format(amount);
};

const isProtectOfferCode = (offerCode) => {
	// Check if Offercode is efax protect
	try {
		offerCode = offerCode.toLowerCase();
		if (offerCode && offerCode.indexOf('_protect_') > -1) {
			return true;
		} else {
			if (offerCode && offerCode.indexOf('_save_') > -1) {
				const values = offerCode.split('_');
				if (values.length > 4 && parseInt(values[3]) > 1000) {
					return true;
				}
			}
		}
		return false;
	} catch {
		return false;
	}
};

const determineCancelLink = () => {
	let determineCancelLinks = Object.assign([], intl.get('CancelLinks'));
	if (determineCancelLinks.length > 1) {
		// Find matching contact based on country code from geoinfo cookie
		const country = userCountry();
		if (country) {
			const result = determineCancelLinks.find((e) => e.CountryCode === country);
			if (result) {
				return result;
			} else {
				return determineCancelLinks[0];
			}
		} else {
			return determineCancelLinks[0];
		}
	} else {
		//only have one just return
		return determineCancelLinks[0];
	}
};

const addRemoveFooterClass = (show) => {
	try {
		//TODO:refactor this logic. Its a hack and we shouldnt do something like this
		var element = document.getElementById('footer');
		if (show) {
			element.className = 'footer-success';
		} else {
			element.className = 'footer-standard';
		}
	} catch {}
};

const appendDividerToDropDown = () => {
	//divider to separate preferred cities and remaining cities
	const divider = {
		name: '---------------------------------------------------------------',
		disabled: true,
		value: '------------------------------------------------------',
	};
	const dividermobile = {
		name: '------------------------------------------------------',
		disabled: true,
		value: '------------------------------------------------------',
	};

	if (!isMobile && isDesktop && (isChrome || isSafari || isFirefox)) {
		return divider;
	} else if (isMobile && !isDesktop) {
		return dividermobile;
	}
};

export {
	isUrlValid,
	GAPageEventTracker,
	GACustomerEventTracker,
	encryptString,
	imageHeaderMapping,
	parsedFullName,
	phoneNumberParser,
	Wrapper,
	cardTypeFix,
	getUserId,
	getClientSessionId,
	generateNewSessionId,
	removeClientSessionId,
	convertTextToTitleCase,
	pageValidation,
	paymentTypesByCountries,
	setQueryParametersInSessionStorage,
	ProperCase,
	errorCodeResponse,
	determineApiUrlEndpoint,
	determineSentryEndpoint,
	getGTMID,
	getHostNameData,
	useStateWithCallback,
	isLocalHost,
	removeDuplicatesFromResponse,
	decryptString,
	determineContactInfo,
	currencyFormatter,
	isProtectOfferCode,
	determineCancelLink,
	addRemoveFooterClass,
	appendDividerToDropDown,
};
