import React, { Suspense, lazy, useEffect, useState, version } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserInfo } from 'lib/funnel-auditor/store/tracker/actions';
import {
	getUserId,
	generateNewSessionId,
	removeClientSessionId,
	getClientSessionId,
	getGTMID,
	determineSentryEndpoint,
} from 'util/Common';
import config from 'systemconfig';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import intl from 'react-intl-universal';
import { Helmet } from 'react-helmet';
import ChooseNumber from 'containers/ChooseNumber';
import Header from 'components/Common/Header';
import Footer from 'components/Common/Footer';
import { verificationCheq, captureSessionTimeout } from 'services/GenericServices';
import SessionTimeout from 'components/Common/SessionTimeout/SessionTimeout';
import { useIdleTimer } from 'react-idle-timer';
import { localizationLookup } from 'util/Localization';
import { userCountry } from 'util/Cookie';
import Cookies from 'js-cookie';
import { RefreshLogoProvider } from '../../Providers/RefreshLogoProvider';

const App = () => {
	const [initDone, setInitDone] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const AccountContainer = lazy(() => import('containers/AccountContainer'));
	const BillingA = lazy(() => import('containers/BillingA'));
	const BillingB = lazy(() => import('containers/BillingB'));
	const SuccessB = lazy(() => import('containers/SuccessB'));
	const SuccessA = lazy(() => import('containers/SuccessA'));
	const SuccessJapan = lazy(() => import('containers/SuccessJapan'));
	const Industry = lazy(() => import('containers/Industry'));
	const NotFound = lazy(() => import('containers/NotFound'));

	useEffect(() => {
		if (sessionStorage.getItem('timeout') && sessionStorage.getItem('timeout') === 'true') {
			//should get triggered if session timeouts and the user tries to refresh the page
			//window.location.href = intl.get('SystemConfig.RedirectPath');
			<Redirect push to={intl.get('SystemConfig.RedirectPath')} />;
		}
		window.onCheqResponse = verfication;

		const singleLocale = localizationLookup();
		// Since intl is a singlton, you don't need to init it again.
		// Just load the locale data in the component.
		//const hostName = getHostNameData();
		const warningHandler = (message, detail) => {}; // Define your custom warning handler
		intl
			.init({
				currentLocale: singleLocale.domain,
				locales: singleLocale.localeItem,
				warningHandler,
			})
			.then(() => {
				// After loading CLDR locale data, start to render
				setGeoInfoCookie();
				setInitDone(true);
			});

		const tagManagerArgs = {
			dataLayer: [],
			gtmId: getGTMID(),
		};
		TagManager.initialize(tagManagerArgs);

		Sentry.init({ dsn: determineSentryEndpoint() });
		// pass unique transactionId and set as Sentry tag
		Sentry.configureScope(function (scope) {
			scope.setTag('transaction_id', getUserId());
		});
	}, []);

	const setGeoInfoCookie = () => {
		let cookie = Cookies.get('GEOINFO');
		if (cookie === undefined || cookie.length === 0) {
			Cookies.set('GEOINFO', intl.get('SystemConfig.FallbackCookie'));
		}
	};

	if (!getClientSessionId()) {
		//only generate a new clientID if missing
		useEffect(() => {
			removeClientSessionId();
			generateNewSessionId();
		}, []);
	}

	const verfication = async (encryptedMessage) => {
		await verificationCheq(encryptedMessage);
	};

	//used to create the page timeout
	const { pause } = useIdleTimer({
		//testing == this.timeout = 15 * 1000 * 1
		timeout: 60 * 1000 * config.sessionTimeout,
		onIdle: () => {
			sessionStorage.setItem('timeout', 'true');
			setShowModal(true);
			const payload = {
				pathname: location.pathname,
				search: location.search,
			};
			captureSessionTimeout(payload);
		},
		crossTab: {
			channelName: 'consensus-faxfunnel-idle-timer',
			fallbackInterval: 1000,
			responseTime: 100,
			removeTimeout: 1000 * 60,
			emitOnAllTabs: true,
		},
		debounce: 250,
	});

	//called only on success page to pause the timer/prevent from redirecting user to first step
	const stopSessionTimeout = () => {
		try {
			pause();
			return true;
		} catch {}
	};

	const successPage = (props) => {
		switch (userCountry()) {
			case 'JP': {
				return <SuccessJapan {...props} />;
			}
			default: {
				return <SuccessA {...props} />;
			}
		}
	};

	return (
		<>
			{(window.location.hostname.includes('devsecure') ||
				window.location.hostname.includes('localhost')) && (
				<div className='currentVersion'>
					Application Version: {config.version}
					<br />
					React Version: {version}
					<div>This is only visible in Dev/Test env</div>
				</div>
			)}

			<SessionTimeout showModal={showModal} />

			<Suspense
				fallback={<div className='fallback-suspense-loading'>{intl.get('PageRoutes.Loading')}</div>}
			>
				{initDone && (
					<>
						<Helmet>
							<title>{intl.get('SystemConfig.HtmlHead.Title')}</title>
							<meta
								name='description'
								content={intl.get('SystemConfig.HtmlHead.MetaDescription')}
							/>
							<meta name='version' content={config.version} />
							<link rel='shortcut icon' id='favicon' href={intl.get('SystemConfig.Favicon')} />
						</Helmet>
						<RefreshLogoProvider>
							<Header />
							<React.StrictMode>
								<Switch>
									<Redirect exact from='/' to={intl.get('PageRoutes.Step1')} />
									<Route
										path={intl.get('PageRoutes.Step1')}
										exact
										render={(props) => <ChooseNumber {...props} />}
									/>
									<Route
										path={intl.get('PageRoutes.Step2')}
										exact
										render={(props) => <AccountContainer {...props} />}
									/>
									<Route
										path={intl.get('PageRoutes.Step3')}
										exact
										render={(props) => <BillingB {...props} />}
									/>
									<Route
										path={intl.get('PageRoutes.Step3ab')}
										exact
										render={(props) => <BillingA {...props} />}
									/>
									{intl.get('PageRoutes.Step4i') && (
										<Route
											path={intl.get('PageRoutes.Step4i')}
											exact
											render={(props) => <Industry {...props} />}
										/>
									)}
									{intl.get('SystemConfig.EnableNewSuccessPage') ? (
										<React.Fragment>
											{window.location.pathname === intl.get('PageRoutes.Step4') &&
												stopSessionTimeout()}
											<Route
												path={intl.get('PageRoutes.Step4')}
												exact
												render={(props) => <SuccessB {...props} />}
											/>
										</React.Fragment>
									) : (
										<React.Fragment>
											{window.location.pathname === intl.get('PageRoutes.Step4') &&
												stopSessionTimeout()}
											<Route
												path={intl.get('PageRoutes.Step4')}
												exact
												render={(props) => successPage(props)}
											/>
										</React.Fragment>
									)}
									{/* * Finally, catch all unmatched routes */}
									<Route component={NotFound} />
								</Switch>
							</React.StrictMode>
						</RefreshLogoProvider>
						<Footer />
					</>
				)}
			</Suspense>
		</>
	);
};

export default connect(null, { setUserInfo })(withRouter(App));
