import React from 'react';
import WithClick from '../../hocs/withclick';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import { useField } from 'formik';
import StyledErrorMessage from '../formikErrorMessage';

const FormikRadio = props => {
	const { clickHandler, text, img, mouseDownHandler, value, ...rest } = props;
	const [field, meta] = useField(props);
	return (
		<>
			{meta.touched && meta.error ? <StyledErrorMessage>{meta.error}</StyledErrorMessage> : null}{' '}
			<label>
				<input {...rest} {...field} type='radio' value={value} />
				{text ? <span>{text}</span> : null}
				{img ? <img src={img} /> : null}
			</label>
		</>
	);
};

export default WithConnect(WithClick(WithCorrelationId(FormikRadio)));
