import React from 'react';
import PropTypes from 'prop-types';
import WithClick from '../../hocs/withclick';
import WithConnect from '../../hocs/withconnect';
import WithCorrelationId from '../../hocs/withCorrelationId';
import styled from 'styled-components';

const StyledContainer = styled.label`
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ span:after {
			display: block;
			left: 6px;
			top: -6px;
			width: 9px;
			height: 19px;
			border: 3px solid #1bab1e;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
			display: inline-block;
			position: relative;
		}
	}
`;

const StyledCheckMark = styled.span`
	position: absolute;
	top: 5px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	height: 20px;
	width: 20px;
	border-radius: 4px;
	background-color: #fff;
	border: 0.5px solid #b5b5b5;
	&:after {
		content: '';
		position: absolute;
		display: none;
		left: 9px;
		top: 3px;
		width: 8px;
		height: 16px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
`;
const CheckBox = props => {
	const { clickHandler, text, mouseDownHandler, ...rest } = props;

	return (
		<StyledContainer>
			{text}
			<input {...rest} onClick={clickHandler} type='checkbox' />
			<StyledCheckMark />
		</StyledContainer>
	);
};

CheckBox.propTypes = {
	text: PropTypes.string.isRequired,
	clickHandler: PropTypes.func.isRequired
};

export default WithConnect(WithClick(WithCorrelationId(CheckBox)));
