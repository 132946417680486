import styled from 'styled-components';
import { device } from 'styles/mediaquery';
import intl from 'react-intl-universal';

const PanoFunnelSteps = styled.div`
	height: 85px;
	width: 100%;
	z-index: 1111;
	@media ${device.mobile} {
		height: 70px;
		margin: 30px 0 0px 0;
	}
	margin: 30px 0 5px 0;
`;

const PanoContainer = styled.div`
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
	max-width: 1170px;
	@media ${device.desktop} {
		width: 100%;
	}
	@media ${device.mobile} {
		width: auto;
	}
`;

const PanoSection = styled.div`
	display: flex;
	justify-content: center;
	width: auto;
	.itemContainer {
		justify-content: center;
		display: flex;
	}
	.headerText {
		position: absolute;
		white-space: nowrap;
		margin-top: 51px;
		@media ${device.mobile} {
			display: none;
		}
	}
`;

const SectionConnector = styled.div`
	width: 90px;
	height: 5px;
	display: flex;
	margin: 20px 5px 0 5px;
	background: ${props => (props.active ? intl.get('MainHeader.Color') : '#D9D9D9')};
	@media ${device.mobile} {
		width: 100%;
	}
`;
const InCompleteCheckMark = styled.div`
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	font-size: 23px;
	background: #fff;
	padding-top: 1px;
	border: 2px solid ${props => (props.active === true ? intl.get('MainHeader.Color') : '#B7B7B7')};
	color: ${props => (props.active === true ? intl.get('MainHeader.Color') : '#B7B7B7')};
	text-align: center;
`;

const CompleteCheckMark = styled.div`
	display: flex;
	.checkmark__circle {
		stroke-dasharray: 166;
		stroke-dashoffset: 166;
		stroke-width: 2;
		stroke-miterlimit: 10;
		stroke: #006697;
		fill: none;
		animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}

	.checkmark {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: block;
		background: #006697;
		box-shadow: inset 0px 0px 0px #7ac142;
		stroke-width: 3;
		stroke: #fff;
		stroke-miterlimit: 10;
	}

	@keyframes stroke {
		100% {
			stroke-dashoffset: 0;
		}
	}

	@keyframes scale {
		0%,
		100% {
			transform: none;
		}

		50% {
			transform: scale3d(1.1, 1.1, 1);
		}
	}

	@keyframes fill {
		100% {
			box-shadow: inset 0px 0px 0px 30px #7ac142;
		}
	}
`;

export {
	InCompleteCheckMark,
	PanoFunnelSteps,
	PanoContainer,
	PanoSection,
	CompleteCheckMark,
	SectionConnector
};
