const size = {
	mobileS: '320px',
	mobileM: '360px',
	mobileL: '480px',
	mobile: '576px',
	tablet: '767px',
	tabletL: '1024px',
	laptop: '992px',
	laptopL: '1200px',
	desktop: '2560px'
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobile: `(max-width: ${size.mobile})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	tabletL: `(max-width: ${size.tabletL})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(max-width: ${size.laptopL})`,
	desktop: `(max-width: ${size.desktop})`,
	desktopL: `(max-width: ${size.desktop})`,
	btwnLaptopAndTabletL: `(min-width:${size.laptop}) and (max-width:${size.tabletL})`
};
