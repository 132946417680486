import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import { StyledH3 } from 'components/StyledComponents';
import { Container, FlexRow, StyledInfo } from './CountrySelectorStyle';
import { userCountry } from 'util/Cookie';
import intl from 'react-intl-universal';
import { SearchableSelect } from 'lib/funnel-auditor';
import { hasSearchByState } from 'util/QueryParams';
const CountrySelector = ({
	loadingCitiesForCountry,
	citiesLoadedForCountry,
	loadingStatesForCountry,
	statesLoadedForCountry,
	onCountrySelect,
	disabledControl
}) => {
	const [countries, setCountries] = useState([]);
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);
	const alternativeCountry = ['JP'];
	const [selectedCountry, setSelectedCountry] = useState('');

	useEffect(() => {
		setLoading(true);
		try {
			const response = intl.get('CountryList');
			if (!response) return null;
			setCountries(response);
			setLoading(false);
			setFailed(false);

			response.length === 1 ? setSelectedCountry(countries[0]) : setSelectedCountry(userCountry);

			let country =
				response.find(c => c.value === userCountry()) !== undefined
					? response.find(c => c.value === userCountry())
					: response[0];
			let searchByOptions = country.searchBy;
			if (hasSearchByState() && country.value === 'US') {
				if (searchByOptions[0] !== 'State') {
					searchByOptions.splice(1, 1);
					searchByOptions.splice(0, 0, intl.get('ChooseNumber.SearchBy.State'));
				}
			}
			onCountrySelect({ countryCode: country ? country.value : userCountry(), searchByOptions });
		} catch (error) {
			setLoading(false);
			setFailed(true);
		}
	}, []);

	const handleChange = event => {
		if (event) {
			const countryCode = event;
			let country = countries.find(c => c.value === countryCode);
			let searchByOptions = country.searchBy;
			setSelectedCountry(countryCode);
			onCountrySelect({ countryCode, searchByOptions });
		}
	};
	return (
		<Container>
			<StyledH3 hideOnSmall>
				{intl.get('ChooseNumber.CountryDropdown.ChooseCountryHeader')}
			</StyledH3>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<StyledInfo>
						{' '}
						{intl.getHTML('ChooseNumber.CountryDropdown.CountryDescription')}
					</StyledInfo>
					{!alternativeCountry.includes(userCountry()) && (
						<FlexRow>
							<SearchableSelect
								placeholder={intl.get('ChooseNumber.CountryDropdown.CountryDropdownPlaceholder')}
								name={'countryDropdown'}
								value={selectedCountry}
								handler={handleChange}
								options={countries}
								disabled={
									disabledControl ||
									(loadingCitiesForCountry && !citiesLoadedForCountry) ||
									(loadingStatesForCountry && !statesLoadedForCountry)
								}
							/>
						</FlexRow>
					)}
				</>
			)}
		</Container>
	);
};
export default CountrySelector;
