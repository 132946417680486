import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './reducers';
import thunk from 'redux-thunk';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['tracker', 'ui']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
	let store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));
	window['store'] = store;
	let persistor = persistStore(store);
	return { store, persistor };
};
