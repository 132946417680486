import React from 'react';
import { useField } from 'formik';
import StyledErrorMessage from '../formikErrorMessage';
import PropTypes from 'prop-types';
import WithConnect from '../../hocs/withconnect';
import WithBlur from '../../hocs/withblur';
import WithCorrelationId from '../../hocs/withCorrelationId';

const FormikInput = props => {
	const [field, meta] = useField(props);
	const { blurHandler, enterHandler, ...restProps } = props;
	const disabled = props.disabled;
	return (
		<>
			<input onBlur={blurHandler} onKeyUp={enterHandler} {...field} {...restProps} />
			{meta.touched && meta.error && !disabled ? (
				<StyledErrorMessage>{meta.error}</StyledErrorMessage>
			) : null}{' '}
		</>
	);
};

FormikInput.propTypes = {
	blurHandler: PropTypes.func.isRequired
};
export default WithConnect(WithBlur(WithCorrelationId(FormikInput)));
