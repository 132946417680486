import React from 'react';
import { StyledRadioButton } from 'components/StyledComponents';
import { getUserId, phoneNumberParser } from 'util/Common';
import intl from 'react-intl-universal';
const PhoneNumber = props => {
	const { phoneNumber, phoneIndex, clickHandler } = props;

	return (
		<StyledRadioButton
			key={phoneNumber.phone_number}
			text={phoneNumberParser(phoneNumber.phone_number)}
			defaultChecked={phoneIndex === 0}
			value={phoneNumber.phone_number}
			data-intformat={phoneNumber.international_format}
			data-pcity={phoneNumber.code}
			loadingtext={intl.get('ChooseNumber.PhoneNumberContainer.ReserveNumberMsg')}
			id={getUserId()}
			doNotLog
			handler={() => clickHandler(phoneNumber.phone_number)}
			name='phoneNumberList'
		/>
	);
};

export default PhoneNumber;
