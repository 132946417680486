import styled from 'styled-components';

const Container = styled.div`
	margin-top: 20px;
`;

const FlexRow = styled.div`
	display: flex;
	margin-top: 5px;
`;
export { Container, FlexRow };
