import React from 'react';
import { PhoneListWrapper, PhoneNumberWrapper } from './PhoneNumberListStyles';
import PhoneNumber from 'components/ChooseNumber/PhoneNumber';
const PhoneNumberList = props => {
	const { handleClick } = props;
	const cssName = props.phoneNumbers.length >= 3 ? 'itemlist' : '';
	const numberList = props.phoneNumbers.map((number, i) => (
		<PhoneNumberWrapper key={number.phone_number} className={cssName}>
			<PhoneNumber
				phoneIndex={i}
				key={number.phone_number + i}
				phoneNumber={number}
				clickHandler={handleClick}
			/>
		</PhoneNumberWrapper>
	));

	return <PhoneListWrapper>{numberList}</PhoneListWrapper>;
};

export default PhoneNumberList;
