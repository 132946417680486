import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import withConnect from '../withconnect';

const withPageLoad = ({ WrappedComponent, isFromNotFound }) =>
	withRouter(
		withConnect(props => {
			const { location, addEvents, postEvents, resetEvents, ...rest } = props;
			useEffect(() => {
				const time = new Date().getTime();
				const date = undefined;
				const hours = undefined;
				const event = 'pageload';
				const page = location.pathname;
				const queryParams = location.search;
				const statusCode = isFromNotFound ? 404 : 200;

				addEvents({ time, date, hours, event, page, queryParams, statusCode });
				setTimeout(() => {
					postEvents({ event, page, queryParams });
					resetEvents();
				}, 900);
			}, []);
			return <WrappedComponent {...rest} />;
		})
	);

withPageLoad.propTypes = {
	addEvents: PropTypes.func.isRequired,
	resetEvents: PropTypes.func.isRequired,
	postEvents: PropTypes.func.isRequired
};

export default withPageLoad;
